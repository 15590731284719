import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Drawer } from 'antd';
import { userService } from '@/service';

const propTypes = {
  showDrawer: PropTypes.bool.isRequired,
  msgId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  readMsg: PropTypes.func,
};
const defaultProps = {
  readMsg: null,
};

@inject(({ messageStore }) => ({
  readMsg: messageStore.readMsg,
}))
@observer
class MessageDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messageRecord: {},
    };
  }

  async componentDidUpdate(prevProps) {
    const { msgId, readMsg } = this.props;
    if (prevProps.msgId !== msgId) {
      const res = await userService.getMsgDetail({ msgId });
      // eslint-disable-next-line
      this.setState({
        messageRecord: res.data,
      });
      // 调用读消息方法，如有必要，则将该消息的已读状态置一，并且将未读总数减一
      readMsg(msgId, res.data.isRead);
    }
  }

  /**
   * 把消息类型的数字转化为字符
   * @param {*} item
   */
  getMsgType = (item) => ['', '微信风控-敏感词', '', '短信风控-敏感词'][Number(item)]

  render() {
    const { showDrawer, onClose } = this.props;
    const { messageRecord } = this.state;
    let msgContent = {};
    try {
      msgContent = JSON.parse(messageRecord?.msgContent);
    } catch (e) {
      msgContent = {
        summary: '',
        messageCount: '',
      };
    }
    return (
      <Drawer
        title="消息详情"
        onClose={onClose}
        visible={showDrawer}
        width={512}
      >
        <div style={{ marginBottom: 20 }}>
          通知类型：
          {this.getMsgType(messageRecord.msgTypeCodeTwoLevel)}
        </div>
        <div style={{ marginBottom: 20 }}>
          { msgContent.summary }
        </div>
        <div style={{ marginBottom: 20 }}>
          触发消息内容：
          { msgContent.messageCount }
        </div>
        <div style={{ marginBottom: 20 }}>
          通知时间：
          {messageRecord.createTime && moment(messageRecord.createTime).format('YYYY-MM-DD hh:mm:ss')}
        </div>
      </Drawer>
    );
  }
}

MessageDetail.propTypes = propTypes;
MessageDetail.defaultProps = defaultProps;

export default MessageDetail;
