import { request } from 'yunkecom/dist/utils';

/**
 * 获取任务列表
 * @param {*} data
 * @returns
 */
export const missionList = (data) => request.postJson('/pc/newPersonalMission/list', data);

/**
 * 获取团队任务列表
 * @param {*} data
 * @returns
 */
export const getPlanList = (data) => request.formData('/pc/plan/list', data);

/**
 * 获取任务中的客户列表
 * @param {*} data
 * @returns
 */
export const getPlanCustomer = (data) => request.get('/pc/plan/getCustomer', data);

/**
 * 创建新的任务
 * @param {*} data
 * @returns
 */
export const addPlan = (data) => request.formData('/pc/plan/addplan', data);
