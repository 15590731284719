import React from 'react';
import ReactDOM from 'react-dom';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { RewriteFrames as RewriteFramesIntegration } from '@sentry/integrations';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {
  registerMicroApps,
  start,
} from 'qiankun';
import 'antd/dist/antd.less';
import './tailwind.css';
import './public/css/main.moudle.less';
import App from './App';

// 子程序注册
const microApps = [
  {
    name: 'customer',
    entry: process.env.REACT_APP_ENTRY_Customer,
    container: '#subapp-viewport',
    activeRule: `${process.env.REACT_APP_ROOT_BASE}/customer`,
    dsn: 'https://880b3f7b86c44e66b885a59e485a79e0@sentry.yunkecn.com/3',
  },
  {
    name: 'wechat',
    entry: process.env.REACT_APP_ENTRY_Wechat,
    container: '#subapp-viewport',
    activeRule: `${process.env.REACT_APP_ROOT_BASE}/wechat`,
    dsn: 'https://c9e63a9f94be43c4ba239abbfd0d3334@sentry.yunkecn.com/5',
  },
  {
    name: 'weixiaozhu',
    entry: process.env.REACT_APP_ENTRY_Weixiaozhu,
    container: '#subapp-viewport',
    activeRule: `${process.env.REACT_APP_ROOT_BASE}/wechatAssistantModules`,
    dsn: 'https://900d5b0070c74280a0ae6d541f3c3cbd@sentry.yunkecn.com/6',
  },
  {
    name: 'settings',
    entry: process.env.REACT_APP_ENTRY_Settings,
    container: '#subapp-viewport',
    activeRule: `${process.env.REACT_APP_ROOT_BASE}/settings`,
    dsn: 'https://8fdbadb7734d4d5c9833cd93da860383@sentry.yunkecn.com/7',
  },
];
// 这几个只有yunke有
if (process.env.REACT_APP_PRIVATE === 'yunke') {
  microApps.push(...[
    {
      name: 'enterpriseWeChat',
      entry: process.env.REACT_APP_ENTRY_Qiyeweixin,
      container: '#subapp-viewport',
      activeRule: `${process.env.REACT_APP_ROOT_BASE}/enterpriseWeChat`,
      dsn: 'https://873228f5a3a84ba59fb530c31058f3ed@sentry.yunkecn.com/8',
    },
    {
      name: 'letssell',
      entry: `${window.location.protocol}//${window.location.host.startsWith('test') || window.location.host.startsWith('qa') ? 'testletssell' : 'letssell'}${process.env.REACT_APP_ENTRY_Letssell}`,
      container: '#subapp-viewport',
      activeRule: `${process.env.REACT_APP_ROOT_BASE}/letssell`,
      dsn: 'https://2fa8fa79e7084304aa0fb87e3f40612b@sentry.yunkecn.com/9',
    },
    {
      name: 'remote',
      entry: process.env.REACT_APP_ENTRY_Remote,
      container: '#subapp-viewport',
      activeRule: `${process.env.REACT_APP_ROOT_BASE}/remote`,
      dsn: 'https://086a6737449d459dba893cd1abebef8b@sentry.yunkecn.com/10',
    },
    {
      name: 'scrm',
      entry: process.env.REACT_APP_ENTRY_Scrm,
      container: '#subapp-viewport',
      activeRule: `${process.env.REACT_APP_ROOT_BASE}/scrm`,
      dsn: 'https://086a6737449d459dba893cd1abebef8b@sentry.yunkecn.com/10',
    },
  ]);
}
if (process.env.REACT_APP_PRIVATE === 'xueqiu-beijing') {
  microApps.push(...[
    {
      name: 'enterpriseWeChat',
      entry: process.env.REACT_APP_ENTRY_Qiyeweixin,
      container: '#subapp-viewport',
      activeRule: `${process.env.REACT_APP_ROOT_BASE}/enterpriseWeChat`,
      dsn: 'https://873228f5a3a84ba59fb530c31058f3ed@sentry.yunkecn.com/8',
    },
  ]);
}
if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_API_ENV === 'main' && process.env.REACT_APP_PRIVATE !== 'lizihang') {
  // 集成验证令牌 b59af9843d15486b80faa2587169ea0a76329c4c2f7545c0ac838fa0d60ebf15
  const userData = localStorage.getItem('userData');
  let user = {
    cellphone: null,
    realName: null,
  };
  if (userData) {
    const userObj = JSON.parse(userData);
    user = {
      cellphone: userObj.cellphone,
      realName: userObj.realName,
    };
  }
  const dsn = 'https://76f9e9643d304786835fcaa6d7c3958e@sentry.yunkecn.com/2';
  // 设置子应用的dsn
  // microApps.forEach((item) => {
  //   if (window.location.pathname.indexOf(item.activeRule) > -1) {
  //     if (item.dsn) {
  //       dsn = item.dsn;
  //     }
  //   }
  // });
  Sentry.init({
    dsn,
    integrations: [
      new Integrations.BrowserTracing(),
      new RewriteFramesIntegration(
        {
          iteratee: (frame1) => {
            const frame = frame1;
            frame.filename = frame.filename.replace(/^https?:\/\/.*\/cms/, '~/build');
            frame.filename = frame.filename.replace(/^https?:\/\/.*\/sub-customer/, '~/build');
            frame.filename = frame.filename.replace(/^https?:\/\/.*\/sub-wechat/, '~/build');
            frame.filename = frame.filename.replace(/^https?:\/\/.*\/sub-weixiaozhu/, '~/build');
            frame.filename = frame.filename.replace(/^https?:\/\/.*\/sub-qiyeweixin/, '~/build');
            frame.filename = frame.filename.replace(/^https?:\/\/.*\/sub-settings/, '~/build');
            console.log(frame, frame1);
            return frame;
          },
        },
      ),
    ],
    tracesSampleRate: 0.5,
    environment: process.env.REACT_APP_API_ENV,
    ignoreErrors: [
      'Failed to fetch',
      'NetworkError when attempting to fetch resource.',
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications',
    ],
    initialScope: {
      user,
    },
    beforeSend(event) {
      // 检查浏览器版本，如果过低，则不必报告错误
      let tooLow = false;
      // eslint-disable-next-line
      if (String.prototype.hasOwnProperty('matchAll')) {
        const edgeVersion = [...navigator.userAgent.matchAll(/edge\/(\d+)\./gmi)];
        if (edgeVersion.length > 0) {
          if (Number(edgeVersion[0][1]) < 37) {
            tooLow = true;
          }
        }
        const chromeVersion = [...navigator.userAgent.matchAll(/chrome\/(\d+)\./gmi)];
        if (chromeVersion.length > 0) {
          if (Number(chromeVersion[0][1]) < 70) {
            tooLow = true;
          }
        }
      } else {
        tooLow = true;
      }
      if (tooLow) return null;
      // 以下设置将打开一个用户界面供用户描述详细操作步骤，慎用
      //   if (event.exception) {
      //     Sentry.showReportDialog({ eventId: event.event_id });
      //   }
      return event;
    },
  });
}

moment.locale('zh-cn');
ReactDOM.render(<App />, document.getElementById('root'));

registerMicroApps(microApps);

// qiankun 通过fetch 请求百度地图 有跨域 问题 所以 过滤 百度地图使用 script 标签加载
start({
  excludeAssetFilter: (url) => {
    const whiteWords = ['baidu'];
    return whiteWords.some((w) => url.includes(w));
  },
});
