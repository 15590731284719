import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { message, Modal } from 'antd';
import moment from 'moment';
import ProLayout from '@ant-design/pro-layout';
import { createFromIconfontCN } from '@ant-design/icons';
import { observer, inject } from 'mobx-react';
import WaterMark from '@pansy/watermark';
import Cookies from 'js-cookie';
import { logout } from '@/global';
import { userService, outboundCallService } from '@/service';
import TopRight from './topRight';
import SecondHeader from './second-header';
import CompanyDrawer from './companyDrawer';

const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_1502858_a2vkj7wfaq9.js',
});
const IconFonts = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_1781333_4s05zjng6g9.js',
});

const IconFontss = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_2951654_jxg5njcpt9.js',
});

const propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    listen: PropTypes.func,
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  siteInfo: PropTypes.shape({
    navigationLogo: PropTypes.string,
  }),
  getSiteInfo: PropTypes.func,
  getUserInfo: PropTypes.func,
  currentMenu: PropTypes.arrayOf(PropTypes.shape()),
  children: PropTypes.shape({}),
  userInfo: PropTypes.shape({
    realName: PropTypes.string,
    cellphone: PropTypes.string,
    userType: PropTypes.number,
  }),
  companyInfo: PropTypes.shape(),
  clearMenuInfo: PropTypes.func,
  menuInfo: PropTypes.arrayOf(PropTypes.shape()),
};
const defaultProps = {
  siteInfo: {},
  getSiteInfo: null,
  getUserInfo: null,
  currentMenu: [],
  children: '',
  userInfo: {},
  companyInfo: {},
  clearMenuInfo: () => {},
  menuInfo: [],
};

const deploy = Object.freeze(process.env.REACT_APP_DEPLOY
  ? (JSON.parse(process.env.REACT_APP_DEPLOY)?.global || {}) : {});

/**
 * 布局
 */
@inject(({ globalStore, siteStore }) => ({
  siteInfo: siteStore.siteInfo,
  getSiteInfo: siteStore.getSiteInfo,
  getUserInfo: globalStore.getUserInfo,
  currentMenu: globalStore.currentMenu,
  showMenu: globalStore.showMenu,
  userInfo: globalStore.userInfo,
  companyInfo: globalStore.companyInfo,
  clearMenuInfo: globalStore.clearMenuInfo,
  menuInfo: globalStore.menuInfo,
}))
@observer
class LayoutComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routes: [],
      topMenu: [],
      pathname: props.history.location.pathname,
      showCompanyDrawer: false,
      fromUrl: new URLSearchParams(props.location.search).get('from') || '',
      number: -1,
      // stepsEnabled: false,
    };
  }

  async componentDidMount() {
    // 检查浏览器版本
    let tooLow = false;
    // eslint-disable-next-line
    if (String.prototype.hasOwnProperty('matchAll')) {
      const edgeVersion = [...navigator.userAgent.matchAll(/edge\/(\d+)\./gmi)];
      // const ss = [...navigator.userAgent.matchAll(/chrome\/(\d+)\./gmi)];
      if (edgeVersion.length > 0) {
        if (Number(edgeVersion[0][1]) < 37) {
          tooLow = true;
        }
      }
    } else {
      tooLow = true;
    }
    if (tooLow) {
      message.warning('您的浏览器版本太低，使用网站时可能会有问题，建议尽快升级至最新版本的浏览器');
    }

    const {
      getSiteInfo, getUserInfo, history, clearMenuInfo,
    } = this.props;
    getSiteInfo();
    const notNeedLogin = this.checkNotNeedLoginPage();
    if (history.location.pathname === '/auth/login') {
      // 先清理
      logout();
      clearMenuInfo();
    } else if (!notNeedLogin) {
      const user = Cookies.get('user');
      if (user) {
        localStorage.setItem('yunkeUser', `user=${user}`);
      }
      const showFengkongMessage = localStorage.getItem('showFengkongMessage');
      if (showFengkongMessage !== moment().format('YYYY-MM-DD') && new Date().getTime() < new Date('2021-09-09').getTime()) {
        Modal.info({
          title: '到期提示',
          width: 600,
          content: (
            <p>微小助风控服务功能将于9月9日晚19时结束公测，如需继续使用，请联系您的商务人员。</p>
          ),
          onOk() {
            localStorage.setItem('showFengkongMessage', moment().format('YYYY-MM-DD'));
          },
        });
      }
      // 对于需要登录的页面，则必须检查用户信息
      getUserInfo();
      this.setMenus();
      // 检查是否曾经运行过引导
      if (localStorage.getItem('doneTour') !== 'yeah!') {
        this.setState({
          // stepsEnabled: true,
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      userInfo, history, currentMenu, location,
    } = this.props;
    const { pathname } = this.state;
    if (prevProps?.userInfo?.realName !== userInfo?.realName) {
      this.watermark();
    }
    if (pathname !== history.location.pathname) {
      // eslint-disable-next-line
      this.setState({
        pathname: history.location.pathname,
        fromUrl: new URLSearchParams(location.search).get('from') || '',
      }, () => this.setMenus());
    }
    if (prevProps.currentMenu.length !== currentMenu.length) {
      this.setMenus();
    }
  }

  setMenus = async () => {
    const { currentMenu: origCurrentMenu, history, userInfo } = this.props;
    let currentMenu = [];
    if (origCurrentMenu && Array.isArray(origCurrentMenu)) {
      currentMenu = origCurrentMenu;
    }
    await this.getQueryChann();
    const { fromUrl, number } = this.state;
    const icons = {
      100101: 'iconweixinkanban',
      100102: 'iconweixinkanban',
      100103: 'iconweixinkanban',
      100501: 'iconweixinkanban',
      100502: 'iconweixinhaoguanli',
      100105: 'iconweixinhaoguanli',
      100104: 'iconweixinhaoguanli',
      100503: 'iconweixinfengkong',
      10050303: 'iconweixinfengkong',
      100301: 'iconweixinfengkong',
      100504: 'iconfuzhuyingxiao',
      100506: 'iconkefuxitong',
      100507: 'iconweixiaoquan',
      100509: 'iconweixiaoquan',
      100508: 'iconyunmingpian',
      100505: 'iconshezhi',
      101601: 'iconweixinhaoguanli',
      101602: 'iconweixinfengkong',
      101401: 'icon12kehuliebiao',
      101402: 'icon20yingxiaorenwu',
      101403: 'icon17fuzhuyingxiao',
      100303: 'icon17fuzhuyingxiao',
      101404: 'icon18qiyeweixinyingxiao',
      100302: 'icon18qiyeweixinyingxiao',
      101405: 'icon9xitongshezhi',
      101603: 'iconshezhi',
      101604: 'iconweixinkanban',
      101703: 'iconshezhi',
      101704: 'iconweixinkanban',
      101701: 'iconweixinhaoguanli',
      100201: 'icon12kehuliebiao',
      100202: 'icon13tonghualishi',
      100203: 'icon14duanxinlishi',
      100204: 'iconweixinfengkong',
      100205: 'icon15qiyegonghai',
      100206: 'icon16xiaoshouluyin',
      100207: 'icon13tonghualishi',
      100801: 'icon12kehuliebiao',
      100802: 'icon13tonghualishi',
      101406: 'iconyingxiaogongju-shoujihaibao',
      102001: 'iconshezhi',
      102002: 'icon12kehuliebiao',
      102003: 'icon20yingxiaorenwu',
      102004: 'icon18qiyeweixinyingxiao',
      102005: 'iconweixinhaoguanli',
      102006: 'iconweixinfengkong',
      userDetail: 'icon-gerenxinxi2',
      import: 'icon-daoru',
      myaccount: 'icon-gerenxinxi1',
      dowload: 'icon-tianchongxing-',
    };

    const windowPath = window.location.pathname + window.location.hash;
    let menu = currentMenu.find((item) => {
      const first = item.children?.find((second) => {
        if (second.url?.startsWith('/pc/cms')) {
          // TODO 这里后端接口有错误
          // eslint-disable-next-line
          second.url = second.url.substring(3);
        }
        if (second.children) {
          return second.children.find(
            (third) => windowPath.includes(third.url) || fromUrl?.includes(third.url),
          );
        }
        return windowPath.includes(second.url) || fromUrl?.includes(second.url);
      });
      if (first) {
        return first;
      }
      if (windowPath.includes(item.url)) {
        return item;
      }
      return null;
    });
    const navigation = ['/dowload',
      '/import',
      '/expenditureDetails',
      '/home/myaccount', '/home/applyOutbound',
      '/home/outboundList', '/home/electronicSales', '/home/OpenTransferOutbound', '/home/transferOutbound', '/home/userDetail'];
    if (menu?.id === '1007' && menu?.children?.length > 10) {
      // 初始的时候设置菜单需要改造一下，改造完成之后就不用再次改造了
      const newMenu = [
        {
          name: '常用设置',
          pId: '1007',
          id: 102001,
          url: '/cms/settings/index',
        },
        {
          name: '用户&权限设置',
          pId: '1007',
          id: 102002,
          children: [],
        },
        {
          name: '自定义业务标签',
          pId: '1007',
          id: 102003,
          children: [],
        },
        {
          name: '业务属性设置',
          pId: '1007',
          id: 102004,
          children: [],
        },
        {
          name: '手机管理',
          pId: '1007',
          id: 102005,
          children: [],
        },
        {
          name: '系统设置',
          pId: '1007',
          id: 102006,
          children: [],
        },
      ];
      menu.children.forEach((item) => {
        newMenu[item.groupSort]?.children?.push(item);
      });
      menu.children = newMenu;
    } else if (navigation.includes(history.location.pathname)
     || history.location.pathname.startsWith('/home/pc')
     || history.location.pathname.startsWith('/home/transferOutbound')
     || history.location.pathname.startsWith('/home/outboundList')
    ) {
      const newMenu = {
        children: [
          {
            name: '个人信息',
            id: 'userDetail',
            url: '/cms/home/userDetail',
            show: true,
          },
          {
            name: '我的账户',
            id: 'myaccount',
            url: '/cms/home/myaccount',
            show: deploy?.my_account && (userInfo.userType === 1 || userInfo.userType === 2),
            children: [
              {
                name: '我的账户',
                id: 'myaccount',
                url: '/cms/home/myaccount',
              },
              {
                name: '收支明细',
                id: 'expenditureDetails',
                url: '/cms/expenditureDetails',
              },
              {
                name: '双向外呼',
                id: 'outboundList',
                url: (number === 3 || number === 11 || number === 4) ? `/cms/home/outboundList/${number}` : '/cms/home/applyOutbound',
              },
              {
                name: '中转外呼',
                url: (number === 9 || number === 10 || number === 8) ? `/cms/home/transferOutbound/${number}` : '/cms/home/OpenTransferOutbound',
              },
              {
                name: '电销卡',
                id: 'electronicSales',
                url: '/cms/home/electronicSales',
              },
              {
                name: '工作手机',
                id: 'phone',
                url: `/cms/home/pc/${encodeURIComponent('https://yunke-pcfile.oss-cn-beijing.aliyuncs.com/html/phone.html')}`,
              },
              {
                name: '企业微信',
                id: 'qiyeweixin',
                url: `/cms/home/pc/${encodeURIComponent('https://yunke-pcfile.oss-cn-beijing.aliyuncs.com/html/qiyeweixin.html')}`,
              },
              {
                name: '外呼线路',
                id: 'waihu',
                url: `/cms/home/pc/${encodeURIComponent('https://yunke-pcfile.oss-cn-beijing.aliyuncs.com/html/waihu.html')}`,

              },
              {
                name: '微小助',
                id: 'weixiaozhu',
                url: `/cms/home/pc/${encodeURIComponent('https://yunke-pcfile.oss-cn-beijing.aliyuncs.com/html/weixiaozhu.html')}`,
              },
            ],
          },
          {
            name: '下载中心',
            id: 'dowload',
            url: '/cms/dowload',
            show: true,
          },
          {
            name: '导入记录',
            id: 'import',
            url: '/cms/import',
            show: deploy?.import_list,
          },
        ],
      };
      menu = {
        children: newMenu?.children?.filter((item) => item.show),
      };
    }
    this.setState({
      topMenu: currentMenu?.filter((_) => _.pId === '0')?.map((item) => ({ // filter  处理接口数据二级导航 放到一级导航上
        path: item.id === menu?.id ? windowPath : item.url,
        name: item.id === menu?.id ? item.name : (
        // 注意：这里不能用Link，因为我们的顶级菜单是用乾坤子应用跳转
        // 所以，这里只能用a标签
          <a href={item.url}>{item.name}</a>
        ),
      })),
      routes: menu?.children?.map((item) => {
        let firstPath = '';
        if (!item.children) {
          if (item.url.startsWith('/cms')) {
            firstPath = item.url;
          } else {
            // eslint-disable-next-line
            firstPath = '/cms/settings' + item.url
          }
        }
        let nameLink = '';
        if (!item.children) {
          if (item.url.startsWith('/cms')) {
            nameLink = item.url.substring(4);
          } else {
            // eslint-disable-next-line
            nameLink = '/settings' + item.url
          }
        }
        return {
          path: firstPath,
          key: firstPath.includes('#') ? new Date().getTime().toString() : firstPath,
          name: item.children ? item.name : (
            <Link
              to={{ pathname: nameLink }}
            >
              {item.name}
            </Link>
          ),
          icon: icons[item.id] ? <IconFonts type={icons[item.id]} /> || <IconFontss type={icons[item.id]} /> : <IconFont type="iconshezhi" />,
          routes: item.children?.map((second) => {
            const pathname = second.url.startsWith('/cms') ? second.url.substring(4) : second.url;
            let name = (
              <Link
                to={{ pathname }}
              >
                {second.name}
              </Link>
            );
            if (pathname === '/geofencing' || pathname.includes('/device/')) {
              name = <a href={second.url}>{second.name}</a>;
            }
            return ({
              path: second.url.replace(/#/, '1'),
              key: second.url.includes('#') ? new Date().getTime().toString() : second.url,
              name,
            });
          }),
        };
      }),
    });
  }

  getQueryChann = async () => {
    if (deploy?.my_account) {
      const { history } = this.props;
      const navigation = ['/dowload',
        '/import',
        '/expenditureDetails',
        '/home/myaccount', '/home/applyOutbound',
        '/home/outboundList', '/home/electronicSales', '/home/OpenTransferOutbound', '/home/transferOutbound', '/home/userDetail'];
      if (navigation.includes(history.location.pathname)
    || history.location.pathname.startsWith('/home/pc')
    || history.location.pathname.startsWith('/home/transferOutbound')
    || history.location.pathname.startsWith('/home/outboundList')
      ) {
        const response = await outboundCallService.queryChannel();
        if (response.success) {
          this.setState({
            number: response.data,
          });
        } else {
          this.setState({
            number: response.code,
          });
        }
      }
    }
  }

  /**
   * 显示水印
   * @returns
   */
  watermark = async () => {
    const { userInfo, history } = this.props;
    // 登录及注册页面不获取水印配置
    const noNeedWatermarkPages = ['/auth/login', 'auth/reg', '/auth/forgotpass', '/auth/accountcheck', '/auth/changephone'];
    if (noNeedWatermarkPages.includes(history.location.pathname)) return;

    const response = await userService.getWatermark().catch((error) => {
      if (error.code === 302) {
        logout('/cms/auth/login');
      }
    });
    if (response?.success) {
      if (response.data.show) {
        const waterMark = new WaterMark();
        waterMark.update({
          text: userInfo.realName + userInfo.cellphone.slice(-4),
          rotate: -20,
        });
        waterMark.render();
      }
    }
  }

  /**
   * 这些页面不需要登录
   * @returns
   */
  checkNotNeedLoginPage = () => {
    const { href } = window.location;
    return /\/login|reg|forgetpass|announce|forgotpass|accountcheck|changephone/.test(
      href,
    );
  }

  /**
   * 这些页面不需要顶部菜单栏
   * @returns
   */
  checkNotNeedLayoutPage = () => {
    const { href } = window.location;
    return /auth\/login|reg|forgetpass|announce|forgotpass|callVoice|salewechat(.*)|saleqq(.*)|accountcheck|changephone|weChatManage|wechatSensitive|collectCustomers/.test(
      href,
    );
  }

  /**
   * 这些页面不需要左侧菜单栏
   * @returns
   */
  checkNotSidebarPage = () => {
    const { href } = window.location;
    return /letssell|remote|qualityTesting|scrm/.test(href);
  }

  render() {
    const {
      children, history, userInfo, companyInfo, siteInfo, menuInfo,
    } = this.props;
    const {
      routes, topMenu, showCompanyDrawer, /* stepsEnabled */
    } = this.state;
    const notNeedLayout = this.checkNotNeedLayoutPage();
    if (notNeedLayout) {
      return (
        <div className="h-full overflow-auto">
          <div id="subapp-viewport">
            {children}
          </div>
        </div>
      );
    }
    const isSecondHeader = ['/cms/home/statistics'].includes(window.location.pathname);
    return (
      <>
        <ProLayout
          layout="top"
          // navTheme="light"
          disableContentMargin
          logo={siteInfo.navigationLogo}
          title={false}
          onMenuHeaderClick={() => { window.location.href = '/cms/'; }}
          route={{
            routes: topMenu,
          }}
          menuProps={{ style: { textAlign: 'center', justifyContent: 'center' } }}
          contentStyle={{ display: 'flex', flexDirection: 'column' }}
          rightContentRender={() => (userInfo
            ? (
              <TopRight
                history={history}
                permissions={menuInfo}
              />
            ) : false)}
        >
          <div className="flex flex-col flex-1 overflow-hidden" style={{ height: 1 }}>
            {/* 只有首页显示 */}
            {isSecondHeader
                && (
                <SecondHeader
                  companyInfo={companyInfo}
                  changeCompanyDrawer={() => this.setState({ showCompanyDrawer: true })}
                />
                )}
            <div className="flex flex-col flex-1 overflow-hidden">
              <ProLayout
                title="首页"
                navTheme="light"
                menuHeaderRender={false}
                menuRender={(props, defaultDom) => (
                  (this.checkNotSidebarPage() || !routes) ? false : defaultDom)}
                route={{
                  routes,
                }}
                contentStyle={{ height: 1 }}
                headerRender={false}
                disableContentMargin
              >
                <div id="subapp-viewport" className="h-full flex-1 flex flex-col subapp">
                  {children}
                </div>
              </ProLayout>
            </div>
            <CompanyDrawer
              visible={showCompanyDrawer}
              onClose={() => this.setState({ showCompanyDrawer: false })}
            />
          </div>
        </ProLayout>
      </>
    );
  }
}

LayoutComponent.propTypes = propTypes;
LayoutComponent.defaultProps = defaultProps;

export default withRouter(LayoutComponent);
