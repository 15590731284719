import qs from 'qs';
import { request } from 'yunkecom/dist/utils';

/**
 * 用户登录
 * @param {*} data
 * @returns
 */
export const login = (data) => request.postJson('/usercenter/login/pcLogin', data).then((res) => res);

/**
 * 用户登录
 * @param {*} data
 * @returns
 */
export const listUserByPhone = (data) => request.postJson('/usercenter/login/listUserByPhone', data).then((res) => res);

/**
 * 用户注册
 * @param {*} data
 * @returns
 */
export const reg = (data) => request.postJson('/usercenter/login/pcReg', data).then((res) => res);

/**
 * 获取短信验证码
 * @param {*} data
 * @returns
 */
export const sendCode = (data) => request.postJson('/usercenter/ycode/sendCode', data).then((res) => res);

/**
 * 密码找回
 * @param {*} data
 * @returns
 */
export const forgotPass = (data) => request.postJson('/usercenter/login/passwordForgot', data);

/**
 * 更新用户手机号
 * @param {*} data
 * @returns
 */
export const updatePhoneAndPwd = (data) => request.postJson('/usercenter/user/updatePhoneAndPwd', data).then((res) => res);

/**
 * 安全校验验证
 * @param {*} data
 * @returns
 */
export const checkCode = (data) => request.postJson('/usercenter/user/checkCode', data).then((res) => res);

/**
 * 修改手机初始密码
 * @param {*} data
 * @returns
 */
export const updatePassword = (data) => request.postJson('/usercenter/user/updatePassword', data).then((res) => res);

/**
 * 获取用户信息
 * @returns
 */
export const getUserInfo = () => request.postJson('/pc/home/userInfo').then((res) => res.data);

/**
 * 修改用户头像
 * @param {*} data
 * @returns
 */
export const uploadHeadImage = (data) => request.formData('pc/crm_t_portaluser/uploadHeadImage', data).then((res) => res.data);

/**
 * 修改密码获取验证码
 * @param {*} data
 * @returns
 */
export const getYzm = (data) => request.postJson('/pc/crm_t_portaluser/getYzm', data).then((res) => res);

/**
 * 修改密码
 * @param {*} data
 * @returns
 */
export const changePwd = (data) => request.postJson('/pc/crm_t_portaluser/changePwd', data).then((res) => res);

/**
 * 修改手机号码
 * @param {*} data
 * @returns
 */
export const changePhone = (data) => request.postJson('/pc/crm_t_portaluser/changePhone', data).then((res) => res);

/**
 * 获取用户公司信息
 * @returns
 */
export const getCompanyInfo = () => request.postJson('/pc/home/getcompanyInfo').then((res) => res.data);

/**
 * 修改用户公司信息
 * @param {*} data
 * @returns
 */
export const changeCompanyinfo = (data) => request.formData('/pc/home/companyinfo', data).then((res) => res);

/**
 * 获取用户菜单
 * @returns
 */
export const getMenu = () => request.get('/pc/authority/permissions').then((res) => res.data);

/**
 * 获取组织架构
 * @param {*} showUser
 * @returns
 */
export const getDepartmentTreeNode = (showUser = false) => {
  const data = { showUser };
  return request.postJson('/pc/home/getDepartmentTreeNode', data);
};

/**
 * 获取组织信息
 * @returns
 */
export const getInfoByCom = () => request.postJson('/pc/channel/infoByCom').then((res) => res.data);

/**
 * 获取组织信息
 * @param {*} url
 * @returns
 */
export const getLogo = (url) => request.postJson('/pc/channel/info', { url }).then((res) => res.data);

/**
 * 请求组织架构树 - 企业微信
 * @param id  请求的id
 * @param showUser 是否显示用户
 * @returns {*}
 */
export const getWeChatDeptTree = (id, showUser = true) => request
  .postJson('/usercenter/dept/treeQ', {
    id,
    showUser,
  })
  .then((res) => res.data);

/**
 * 查询组织结构
 * @param keyword 关键字
 * @param type 1部门 2用户 3全部（全部查询时 phoneOnly 与 limit无效）
 * @param limit
 * @param phoneOnly
 */
export const searchUserDept = (keyword, type = 3, limit = 20, phoneOnly = false) => {
  const url = '/usercenter/dept/search';
  const data = {
    keyword, type, limit, phoneOnly,
  };
  return request.postJson(url, data).then((res) => res.data);
};

/**
 * 获取RSA公钥
 */
export const getModulus = () => {
  const url = '/usercenter/login/getSecureKey';
  const data = {};
  return request.postJson(url, data).then((res) => res.data);
};

/**
 * 获取渠道信息
 */
export const getChannelInfo = () => {
  const url = '/usercenter/company/getChannelInfo';
  const data = {};
  return request.postJson(url, data).then((res) => res.data);
};

/**
 * 获取个人版组织架构
 * @param {*} id
 * @param {*} showUser
 */
export const getChanelInfo = (id, showUser = true) => request
  .postJson('/usercenter/dept/innerTree', {
    id,
    showUser,
  });

/**
 * 搜索个人版组织架构
 * @param {*} keyword
 */
export const searchChanelInfo = (keyword) => {
  const url = '/usercenter/dept/innerSearch';
  const data = {
    keyword, type: 2, limit: 20, showUser: true,
  };
  return request.postJson(url, data).then((res) => res.data);
};

/**
 * 获取渠道信息
 * @param {*} pageIndex
 * @param {*} pageSize
 */
export const getSensitiveWordsList = (pageIndex, pageSize) => {
  const url = '/pc/sensitiveWorld/query';
  const data = { page: pageIndex, pageSize };
  return request.get(url, data).then((res) => res.data);
};

/**
 * 获取渠道信息
 * @param {*} sensitiveClassify
 * @param {*} sensitiveWords
 */
export const addSensitiveWord = (sensitiveClassify, sensitiveWords) => {
  const url = '/pc/sensitiveWorld/add';
  const data = { sensitiveWords, sensitiveClassify };
  return request.postJson(url, data);
};

/**
 * 获取渠道信息
 * @param {*} sensitiveClassify
 * @param {*} sensitiveWords
 * @param {*} id
 */
export const editSensitiveWord = (sensitiveClassify, sensitiveWords, id) => {
  const url = '/pc/sensitiveWorld/update';
  const data = { sensitiveWords, sensitiveClassify, id };
  return request.postJson(url, data);
};

// 获取渠道信息
export const deleteSensitiveWord = (id) => {
  const url = '/pc/sensitiveWorld/delete';
  const data = { id };
  return request.formData(url, data);
};

/**
 * 获取渠道信息
 * @param {*} id
 */
export const getSensitiveWordById = (id) => request.get('/pc/sensitiveWorld/query/id', { id }).then((res) => res.data);

/**
 * 获取首页今日意向客户
 * @param {*} data
 */
export const getRanking = (data) => request.formData('/pc/home/setranking', data);

// /**
//  * 获取首页统计信息
//  * @param {*} data
//  */
// export const getHomeStatistics = (data) => request.formData('/pc/home/statistics', data);

/**
 * 获取首页top统计信息
 * @param {*} data
 */
export const getHomeTopStatistics = (data) => request.formData('/pc/home/topStatistics', data);

/**
 * 获取首页call统计信息
 * @param {*} data
 */
export const getHomeCallStatistics = (data) => request.formData('/pc/home/callStatistics', data);

/**
 * 获取首页callresult统计信息
 * @param {*} data
 */
export const getHomeCallResultStatistics = (data) => request.formData('/pc/home/callResultStatistics', data);

/**
 * 获取首页动态生成图表信息
 * @param {*} data
 */
export const getReportChartData = (data) => request.formData('/pc/showField/report', data);

/**
 * 获取部门管理员工列表
 * @param {*} param0
 */
export const findByDepartment = ({
  page, departmentId, realName, pageSize, userType,
}) => request.postForm('/pc/crm_t_portaluser/findByDepartment', qs.stringify({
  page, setPage: false, departmentId, realName, pageSize, userType,
})).then((res) => res.data);

/**
 * 获取跨部门管理管理员列表
 * @param {*} param0
 */
export const getDepartmentManager = ({ page, departmentId }) => {
  const url = '/pc/crm_t_department/query/departmentManager';
  const data = { page, setPage: true, departmentId };
  return request.postJson(url, data).then((res) => res.data);
};

/**
 * 获取部门下拉列表
 */
export const getDepartmentList = () => {
  const url = '/pc/home/getDepartmentTreeNode';
  const data = qs.stringify({ showUser: false });
  return request.postForm(url, data);
};

/**
 * 新建员工
 * @param {*} data
 */
export const AddStaffList = (data) => {
  const url = '/pc/crm_t_portaluser/add';
  return request.postJson(url, data);
};

/**
 * 获取角色下拉列表
 */
export const getRoleList = () => {
  const url = '/pc/authority/role/getAll';
  return request.get(url).then((res) => res.data);
};

/**
 * 获取角色权限
 */
export const searchMenuTreeByRoleId2 = (data) => request.get(
  '/pc/authority/serch/serchMenuTreeByRoleId2',
  data,
).then((res) => res.data);

/**
 * 添加或修改角色
 * @param {*} data
 */
export const roleUpsert = (data) => request.postJson('/pc/authority/role', data);

/**
 * 删除角色
 * @param {*} data
 */
export const roleDelete = (data) => request.deleteJson('/pc/authority/role/delete', data);

/**
 * 保存权限
 * @param {*} data
 */
export const saveAuthority = (data) => request.postJson('/pc/authority/operation/authority2', data);

/**
 * 获取自动分配客户规则列表
 * @param {*} data
 */
export const getRuleList = (data) => request.postJson(
  '/pc/customer_rule/get_rule_list',
  data,
).then((res) => res.data);

/**
 * 根据ID获取规则，如果ID为0，则获取空规则
 * @param {*} data
 */
export const getRuleById = (data) => request.formData('/pc/customer_rule/get_rule_by_id', data);

/**
 * 设置规则顺序
 * @param {*} data
 */
export const setRuleOrder = (data) => request.formData('/pc/customer_rule/set_rule_order', data);

/**
 * 保存客户自动分配规则
 * @param {*} data
 */
export const saveCustomerRule = (data) => request.postJson('/pc/customer_rule/set_rule', data);

/**
 * 删除规则
 * @param {*} data
 */
export const deleteRuleById = (data) => request.formData('/pc/customer_rule/delete_rule_by_id', data);

/**
 * 设置规则启用或禁用
 * @param {*} data
 */
export const setRuleSwitch = (data) => request.formData('/pc/customer_rule/set_rule_switch', data);

/**
 * 获取应用白名单
 * @param {*} data
 */
export const appPermissions = (data) => request.get('/pc/setup/appPermissions', data);

/**
 * 插入新的应用
 * @param {*} data
 */
export const saveAppPermission = (data) => request.postJson('/pc/setup/saveAppPermission', data);

/**
 * 删除应用
 * @param {*} data
 */
export const deleteAppPermission = (data) => request.postJson('/pc/setup/deleteAppPermission', data);

/**
 * 获取消息列表
 * @param {*} data
 */
export const getMsgList = (data) => request.postJson('/msgcenter/web/getMsgList', data);

/**
 * 获取单条消息详情，并设置单条消息已读
 * @param {*} data
 */
export const getMsgDetail = (data) => request.postJson('/msgcenter/web/getMsgDetails', data);

/**
 * 标记所有消息为已读
 * @param {*} data
 */
export const setAllMsgRead = (data) => request.postJson('/msgcenter/web/updateIsRead', data);

/**
 * 查找用户打卡信息
 * @param {*} data
 */
export const signSearchForUser = (data) => request.get('/pc/home/signSearchForUser', data);

/**
 * 查看用户打卡图片
 * @param {*} data
 */
export const getSignImg = (data) => request.get('/pc/home/getRecordPics', data);

/**
 * 修改员工
 */
export const EditStaffList = ({ userRealname, userCellphone, ...params }) => {
  const url = '/pc/crm_t_portaluser/edit/info';
  const data = qs.stringify({ name: userRealname, phone: userCellphone, ...params });
  return request.postForm(url, data);
};

/**
 * 导入员工
 */
export const postFile = (data) => {
  const url = '/pc/crm_t_portaluser/importUserexcel';
  return request.formData(url, data);
};
/**
 *离职处理
 */
export const setQuitStaff = (data) => {
  const url = '/pc/setup/unBindMemberAndDevice';
  const params = qs.stringify(data);
  return request.postForm(url, params);
};
/**
 *获取企业公海下拉框
 */
export const querySeaAuth = () => request.get('/pc/customer/querySeaAuth').then((res) => res.data);

/**
 *添加编辑部门
 */
export const addDepartment = (data) => {
  const url = '/pc/crm_t_department/save';
  const params = qs.stringify(data);
  return request.postForm(url, params);
};

/**
 *添加编辑部门
 */
export const delDepartment = (data) => {
  const url = '/pc/crm_t_department/delete';
  const params = qs.stringify(data);
  return request.postForm(url, params);
};

/**
 *设置管理员
 */
export const settingAdmin = (data) => {
  const url = '/pc/crm_t_portaluser/setting';
  const params = qs.stringify(data);
  return request.postForm(url, params);
};

/**
 * 重置密码
 */
export const RestPassword = (data) => {
  const url = '/pc/setup/resetOnePwd';
  const params = qs.stringify(data);
  return request.postForm(url, params);
};

/**
 * 变更角色
 */
export const setChangeRoles = ({ userId, roleId }) => {
  const url = `/pc/authority/operation/single/${userId}/${roleId}`;
  return request.get(url);
};

/**
 *设置多部门管理员
 */
export const setMultiSector = (data) => {
  const url = '/pc/crm_t_department/setDepartmentManager';
  return request.postJson(url, data);
};

/**
 * 设置跨部门管理员
 */
export const setCrossSectoral = (data) => {
  const url = '/pc/crm_t_department/setManagerToDept';
  return request.postJson(url, data);
};
/**
 *变更部门
 */
export const changeDepartment = (data) => {
  const url = '/pc/crm_t_portaluser/changeDepartment';
  return request.postJson(url, data);
};

/**
 * 跨部门管理详情
 */
export const getDepartmentManagerIds = (data) => request.postJson('/pc/crm_t_department/getDepartmentManagerIds', data);

/**
 * 获取多部门管理详情
 */
export const getMangeMentList = (data) => {
  const url = '/pc/crm_t_department/query/manageDeartmentIds';
  return request.postJson(url, data);
};

/**
 * 设置-操作日志列表
 */
export const getOperateLogs = (data) => {
  const url = '/pc/operate/logs';
  return request.get(url, data);
};

/**
 * 设置-操作日志类型
 */
export const getOperateTypes = ({ group, module }) => {
  const data = { group, module };
  const url = '/pc/operate/types';
  return request.get(url, data);
};

/**
 * 用户登录 统计
 */
export const loginStatistics = () => request.postJson('/pc/home/logLogin ');
/**
 * 找回密码统计
 */
export const passwordStatistics = () => request.postJson('/pc/home/changePwdlog');

/**
 * 设置-操作日志模块
 */
export const getOperateModules = ({ group }) => {
  const data = { group };
  const url = '/pc/operate/modules';
  return request.get(url, data);
};

/**
 * 下载中心
 */
export const getfindFile = ({ page }) => {
  const data = { page };
  const url = '/pc/download_center/findFile';
  return request.postForm(url, qs.stringify(data));
};

/**
 * 下载操作日志添加
 */
export const addLog = (data) => {
  const url = '/pc/download_center/add_log';
  return request.postForm(url, qs.stringify(data));
};

export const parentDepId = () => request.postJson('/usercenter/dept/tree', { showUser: true });

/**
 * 是否开启水印
 */
export const getWatermark = () => {
  const url = '/pc/safe/watermark';
  return request.postJson(url);
};

/**
 * 插拔卡统计
 * /pc/simstatus/records
 */

export const getSimstatus = (data) => request.postJson('/pc/simstatus/records', data);

/**
 *  插拔卡选项
 * /pc/simstatus/conditionItem
 */
export const getconditionItem = () => request.postJson('/pc/simstatus/conditionItem', { type: 1 });

/**
 * 电销卡申请
 */
export const applyCard = (data) => request.postJson('/pc/telemarketing/applyCard', data);

/**
 * 添加上线充值二维码
 */

export const addRechargeCode = (data) => request.postJson('/cashier/web/pay/manager/addRechargeCode', data);

/**
 * 薄言质检退出重新登录
 */

export const loginOut = (data) => request.postJson('/pc/boyan/loginOut', data);

/**
 * 导入记录列表
 */
export const getImportList = (data) => request.postJson('/pc/customer/getCustomerImportHistory', data);

/**
 * 收入明细
 */
export const getExpenditureList = (data) => request.postJson('/pc/amount/list', data);
