// 设备模块路由，首页新页面路由别在这里添加
import { lazy } from 'react';

const DeviceStatic = lazy(() => import('@/pages/device/deviceStatic'));
const Phonescreenshot = lazy(() => import('@/pages/home/phoneClient'));
const MobileCard = lazy(() => import('@/pages/home/mobilecard'));
const MobileLocation = lazy(() => import('@/pages/home/mobilelocation'));
const Geofencing = lazy(() => import('@/pages/geofencing'));

export default [
  /**
   * 设备/手机统计
   */
  {
    name: '设备统计',
    path: '/device/devicestatistics',
    key: '/device/devicestatistics',
    component: DeviceStatic,
  },
  {
    name: '手机截屏',
    path: '/device/phonescreenshot',
    key: '/device/phonescreenshot',
    component: Phonescreenshot,
  },
  {
    name: '插拔卡统计',
    path: '/device/mobileCard',
    key: '/device/mobileCard',
    component: MobileCard,
  },
  /**
   * 设备/手机定位
   */
  {
    name: '手机定位',
    path: '/device/mobilelocation',
    key: '/device/mobilelocation',
    component: MobileLocation,
  },
  {
    name: '围栏设置',
    path: '/device/geofencing',
    key: '/device/geofencing',
    component: Geofencing,
  },
];
