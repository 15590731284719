import { lazy } from 'react';

const Statistics = lazy(() => import('@/pages/home/statistics'));
const Sign = lazy(() => import('@/pages/home/sign'));
const Appstatistics = lazy(() => import('@/pages/home/appstatistics'));
const MobileLocation = lazy(() => import('@/pages/home/mobilelocation'));
const OutboundList = lazy(() => import('@/pages/home/my/Outbound'));
const MyAccount = lazy(() => import('@/pages/home/my/account'));
const ReportHome = lazy(() => import('@/pages/home/reportform/home'));
const ReportCall = lazy(() => import('@/pages/home/reportform/call'));
const ReportMsg = lazy(() => import('@/pages/home/reportform/msg'));
const ReportWechat = lazy(() => import('@/pages/home/reportform/wechat'));
const ReportDetail = lazy(() => import('@/pages/home/reportform/detail'));
const ReportList = lazy(() => import('@/pages/home/reportform/list'));
const ReportWechatList = lazy(() => import('@/pages/home/reportform/wechatlist'));
const ReportDownload = lazy(() => import('@/pages/home/reportform/download'));
const MessageCenter = lazy(() => import('@/pages/home/messageCenter'));
const Download = lazy(() => import('@/pages/dowload'));
const MissionList = lazy(() => import('@/pages/mission/list'));
const MissionDetail = lazy(() => import('@/pages/mission/detail'));
const MissionPlan = lazy(() => import('@/pages/mission/plan'));
const ElectronicSales = lazy(() => import('@/pages/home/my/electronicSales'));
const transferOutbound = lazy(() => import('@/pages/home/my/transferOutbound'));
const OpenTransferOutbound = lazy(() => import('@/pages/home/my/transferOutbound/open'));
const HomePC = lazy(() => import('@/pages/iframepage/index'));
const QualityTesting = lazy(() => import('@/pages/iframepage/qualityTesting'));
const ApplyOutbound = lazy(() => import('@/pages/home/my/Outbound/applyOutbound'));
const ElectronList = lazy(() => import('@/pages/home/my/electronicSales/list'));
const ImportList = lazy(() => import('@/pages/ImportList/index'));
const userDetail = lazy(() => import('@/pages/layout/userDetail'));
const ExpenditureDetails = lazy(() => import('@/pages/expenditureDetails/index'));

export default [
  {
    name: '数据统计',
    path: '/home/statistics',
    key: '/statistics',
    component: Statistics,
  },
  {
    name: '打卡统计',
    path: '/home/sign',
    key: '/sign',
    component: Sign,
  },
  {
    name: '手机定位',
    path: '/home/mobilelocation',
    key: '/mobilelocation',
    component: MobileLocation,
  },
  {
    name: '手机统计',
    path: '/home/appstatistics',
    key: '/appstatistics',
    component: Appstatistics,
  },
  {
    name: '双向外呼',
    path: '/home/outboundList/:type',
    key: 'outboundList',
    component: OutboundList,
  },
  {
    name: '我的账户',
    path: '/home/myaccount',
    key: '/myaccount',
    component: MyAccount,
  },
  {
    name: '报表统计',
    path: '/home/reportform/home',
    exact: true,
    key: '/reporthome',
    component: ReportHome,
  },
  {
    name: '电话统计',
    path: '/home/reportform/call',
    exact: true,
    key: '/reportcall',
    component: ReportCall,
  },
  {
    name: '短信统计',
    path: '/home/reportform/msg',
    exact: true,
    key: '/reportmsg',
    component: ReportMsg,
  },
  {
    name: '微信统计',
    path: '/home/reportform/wechat',
    exact: true,
    key: '/reportwechat',
    component: ReportWechat,
  },
  {
    name: '报表详情',
    path: '/home/reportform/:module/detail',
    exact: true,
    key: '/reportdetail',
    component: ReportDetail,
  },
  {
    name: '报表个人详情',
    path: '/home/reportform/:module/list',
    exact: true,
    key: '/reportList',
    component: ReportList,
  },
  {
    name: '报表微信个人详情',
    path: '/home/reportform/wechat/wechatlist',
    exact: true,
    key: '/reportWechatList',
    component: ReportWechatList,
  },
  {
    name: '报表导出',
    path: '/home/reportform/home/download',
    exact: true,
    key: 'reportdownload',
    component: ReportDownload,
  },
  {
    name: '消息中心',
    path: '/home/messageCenter',
    key: 'messageCenter',
    component: MessageCenter,
  },
  {
    name: '下载中心',
    path: '/dowload',
    key: 'download',
    component: Download,
  },
  {
    name: '任务列表',
    path: '/mission/list',
    key: 'missionList',
    component: MissionList,
  },
  {
    name: '任务详情',
    path: '/mission/detail',
    key: 'missionDetail',
    component: MissionDetail,
  },
  {
    name: '团队任务',
    path: '/mission/plan',
    key: 'missionPlan',
    component: MissionPlan,
  },
  {
    name: '电销卡',
    path: '/home/electronicSales',
    key: 'electronicSales',
    component: ElectronicSales,
  },
  {
    name: '中转外呼',
    path: '/home/transferOutbound/:type',
    key: 'transferOutbound',
    component: transferOutbound,
  },
  {
    name: '开通中转外呼',
    path: '/home/OpenTransferOutbound',
    key: 'OpenTransferOutbound',
    component: OpenTransferOutbound,
  },
  {
    name: '云客产品',
    path: '/home/pc/:url',
    key: 'homePC',
    component: HomePC,
  },
  {
    name: '云客质检',
    path: '/qualityTesting',
    key: 'QualityTesting',
    component: QualityTesting,
  },
  {
    name: '双向外呼申请',
    path: '/home/applyOutbound',
    key: 'applyOutbound',
    component: ApplyOutbound,
  },
  {
    name: '电销卡激活列表',
    path: '/home/electronList',
    key: 'electronList',
    component: ElectronList,
  },
  {
    name: '导入记录',
    path: '/import',
    key: 'importList',
    component: ImportList,
  },
  {
    name: '个人中心',
    path: '/home/userDetail',
    key: 'userDetail',
    component: userDetail,
  },
  {
    name: '收支明细',
    path: '/expenditureDetails',
    key: 'expenditureDetails',
    component: ExpenditureDetails,
  },
];
