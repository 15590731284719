import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import { InsertRowLeftOutlined } from '@ant-design/icons';
import { tools } from 'yunkecom/dist/utils';
import certified from '@/public/images/certified.png';
import firm from '@/public/images/firm.png';

const propTypes = {
  companyInfo: PropTypes.shape({
    imgUrl: PropTypes.string,
    name: PropTypes.string,
  }),
  changeCompanyDrawer: PropTypes.func,
};
const defaultProps = {
  companyInfo: {},
  changeCompanyDrawer: () => {},
};

class SecondHeader extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { companyInfo, changeCompanyDrawer } = this.props;
    return (
      <div className="flex items-center h-12 px-8 bg-white border-b">
        <Avatar
          size={36}
          src={tools.forceHttps(companyInfo.imgUrl) || firm}
          icon={<InsertRowLeftOutlined />}
        />
        {companyInfo.name && (
          <>
            <div
              className="text-lg font-medium mx-4"
              role="button"
              tabIndex="0"
              onKeyPress={() => {}}
              onClick={() => changeCompanyDrawer(true)}
            >
              {companyInfo.name}
            </div>
            <img
              className="w-4"
              src={certified}
              alt=""
            />
          </>
        )}
      </div>
    );
  }
}

SecondHeader.propTypes = propTypes;
SecondHeader.defaultProps = defaultProps;

export default SecondHeader;
