import qs from 'qs';
import { request, tools } from 'yunkecom/dist/utils';

/**
 * 客户列表接口
 */
export const customerList = (data) => request.postJson('/pc/customer/list', data);

export const newCustomerList = (data) => request.postJson('/pc/newcustomer/customers', data);

/**
 * 客户列表表头获取
 */
export const getDefaultHead = (data) => request.formData('/pc/customer/getDefaultHead', data);

export const defaultHead = (data) => request.formData('/pc/customer/getDefaultHead', data);

/**
 * 设置表头显示字段
 */
export const setShowFieldTableHeadCustomer = (data) => request.postJson('/pc/customer/setShowFieldTableHead', data);

export const setSeaShowFieldTableHead = (data) => request.postJson('/pc/customer/setSeaShowFieldTableHead', data);

/**
 * 获取新增客户表单字段数据
 */
export const getIsShowShowFields = (data = { positionType: 0 }) => request.formData('/pc/showField/getIsShowShowFields', data);

/**
 * 获取客户详情信息
 */
export const getCustomerDetail = (data) => request.formData('/pc/customer/detail', data);

/**
 * 微小助-客户列表
 */
// 获取销售进度和自定义表头
export const getUsingProgressesAndHeader = (data) => request.formData('/pc/wechatCustomer/getUsingProgressesAndHeader', data);

// 设置自定义表头
export const setShowFieldTableHead = (data) => request.postJson('/pc/wechatCustomer/setShowFieldTableHead', data);

// 查询常用条件
export const getConditions = () => request.formData('/pc/wechatCustomer/getConditions');

// 编辑/新增常用条件
export const setConditions = (data) => request.formData('/pc/wechatCustomer/setConditions', data);

// 删除常用条件
export const delConditions = (data) => request.formData('/pc/wechatCustomer/delConditions', data);

// 查询微小助客户列表
export const getCustomerList = (data) => request.postJson('/pc/wechatCustomer/getCustomerList', data);

// 微小助客户列表导出
export const exportCustomerList = (data) => {
  const opt = { ...data };
  return request.postJson('/pc/wechatCustomer/exportCustomerList', opt);
};

/** -------- 短信历史相关 --------- */
// 获取历史短信列表
export const getHistoryMsg = (params = {}) => {
  const data = { ...params };
  return request.postJson('/pc/customer/smsLogsList', data);
};
// 获取结构树
export const getDepTree = (params = {}) => {
  const data = { ...params };
  return request.postJson('/usercenter/dept/tree', data);
};
// 历史短信导出
export const exportXls = (params = {}) => {
  const data = { ...params };
  return request.postJson('/pc/customer/exportXls', data);
};
// 获取敏感词列表
export const getSensitiveWordList = (params = {}) => {
  const data = { ...params };
  return request.get('/pc/sensitiveWorld/query/sensitiveClassify', data);
};
// 获取敏感词
export const getSensitiveWord = (params = {}) => {
  const data = { ...params };
  return request.get('/pc/sensitiveWorld/query/sensitiveWord', data);
};
// 获取敏感词
export const updateSmsHistory = (params = {}) => {
  const data = { ...params };
  return request.formData('/pc/sensitiveWorld/update/smsHistory', data);
};

/**
 * 微小助-标签管理
 */
// 查询客户标签及分组
export const queryTags = (data) => request.postJson('/pc/customerTag/queryTags', data);
// 添加分组
export const addTagGroup = (data) => request.postJson('/pc/customerTag/addTagGroup', data);
// 编辑分组
export const editGroupAndAddTags = (data) => request.postJson('/pc/customerTag/editGroupAndAddTags', data);
// 删除标签分组
export const delTagsOrGroup = (data) => request.postJson('/pc/customerTag/delTagsOrGroup', data);
// 根据分组id查询分组及标签
export const queryTagById = (data) => request.postJson('/pc/customerTag/queryTagById', data);

/**
 * 手机截屏列表
 * @param data
 * @returns {*}
 */
export const getPhoneList = ({ pageIndex, pageSize, ...searchOptions }) => {
  const data = { page: pageIndex, size: pageSize, ...searchOptions };
  return request.postJson('/pc/document/screenshot', data).then((res) => res.data);
};

/**
 * 手机截屏列表
 * @returns {*}
 */
export const deletePhoneList = (ids) => {
  const data = { ids };
  return request.postJson('/pc/document/screenshotDel', data);
};

/**
 * 销售进度
 * @returns {*}
 */
export const getSaleSchedule = () => {
  const url = '/pc/callTip/getAllShowSalesProgresses';
  return request.get(url).then((res) => tools.checkSelectList(res.data));
};

/**
 * 电话结果
 * @returns {*}
 */
export const getPhoneType = () => request.postJson('/pc/customer/getCalltips').then((res) => tools.checkSelectList(res.data, 'name'));

export const getCustomerSource = () => {
  const url = '/pc/customer/getSource';
  return request.postJson(url).then((res) => tools.checkSelectList(res.data, 'customerSource', 'customerSource'));
};

export const getProvinceList = () => {
  const url = '/pc/phoneRegist/getAllProvinceAndCity';
  return request.postJson(url).then((res) => tools.checkSelectList(res.data.allProvinces, 'id', 'provinceName'));
};

export const getCityList = (provinceId) => {
  const url = '/pc/phoneRegist/getCityByProvinceId';
  return request.formData(url, { provinceId }).then((res) => tools.checkSelectList(res.data, 'id', 'cityName'));
};

export const postFile = (data) => {
  const url = '/pc/customer/import2sea';
  return request.formData(url, data);
};

export const deleteCustomer = (data) => {
  const url = '/pc/newcustomer/delcustomer';
  return request.postJson(url, data);
};

export const deleteSeaCustomer = (data) => {
  const url = '/pc/newcustomer/delseacustomer';
  return request.postJson(url, data);
};

export const changeUser = (data) => {
  const url = '/pc/customer/changeuser-v2';
  return request.formData(url, data);
};
export const changeusersss = (data) => {
  const url = '/pc/newcustomer/changeuser';
  return request.postJson(url, data);
};

export const changeusersea = (data) => {
  const url = '/pc/newcustomer/seachangeuser';
  return request.postJson(url, data);
};

export const addEditPeople = (data) => {
  const url = '/pc/customer/addEditor';
  return request.postJson(url, data);
};

export const addEditor = (data) => {
  const url = '/pc/newcustomer/addeditor';
  return request.postJson(url, data);
};

export const delEditor = (data) => {
  const url = '/pc/newcustomer/deleditor';
  return request.postJson(url, data);
};

export const deleteEditPeople = (data) => {
  const url = '/pc/customer/delEditor';
  return request.postJson(url, data);
};

export const backAdd = (data) => {
  const url = '/pc/blacklist/addDetailByUserIds';
  return request.postJson(url, data);
};

export const beforeExport = (data) => {
  const url = `/pc/newcustomer/customer/export?type=${data.type}`;
  return request.postJson(url, data);
};

export const addCustomer = (data) => {
  const url = '/pc/customer/addCustomerAndCustomValues';
  return request.postJson(url, data);
};

export const getBlackDataList = () => {
  const url = '/pc/blacklist/queryBlackGroupByPower';
  return request.postJson(url).then((res) => tools.checkSelectList(res.data, 'id', 'name'));
};

export const getCustomerShowFont = (positionType = 1) => {
  const url = '/pc/showField/getIsShowShowFields';
  return request.formData(url, { positionType }).then((res) => res.data);
};

export const saveCustomerDetailAndCustomValues = (data) => {
  const url = '/pc/customer/saveCustomerDetailAndCustomValues';
  return request.postJson(url, data).then((res) => res.data);
};

export const getCalltips = () => {
  const url = '/pc/customer/getCalltips';
  return request.postJson(url).then((res) => res.data);
};

export const getMsgTemplate = () => {
  const url = '/pc/customer/getMsgTemplate';
  return request.get(url, { msgType: 1 }).then((res) => res.data);
};

export const endCallMsg = (data) => {
  const url = '/pc/newpersonalSms/endCallMsg';
  return request.formData(url, data);
};

// /customer/new/detail
export const getCustomerDetails = (customerId) => request.formData('/pc/newcustomer/detail', { customerId, positionType: 1 }).then((res) => res.data);

export const getCustomerClick = (customerId) => request.formData('/pc/newcustomer/detail', { customerId, positionType: 1 }).then((res) => res);

export const phoneCallCheckBk = (data) => request.formData('/pc/newPersonalCall/phoneCallCheckBk', data);

export const hangUpPhoneCall = (id) => request.get('/pc/newPersonalCall/hang_up_phone_call', { callId: id });
export const handUpYunkecc = (id) => request.get('/pc/newPersonalCall/handUpYunkecc', { aliCallId: id });

export const addCustomerCall = (data) => {
  const params = qs.stringify(data);
  return request.postForm('/pc/newPersonalCall/addCustomerCall', params);
};

export const showCall = (data) => request.formData('/pc/newPersonalCall/showCall', data).then((res) => res.data);

export const customerCustomFieldAndValues = (data) => request.formData('/pc/showField/customerCustomFieldAndValues', data).then((res) => res.data);

/**
 * num=" + phone + "&pid=" + planCustomerId + "&type=" + callInterType;
 * @param data
 * @returns {*}
 */
export const getYunkeccAuth = (data) => request.get('/pc/newPersonalCall/getYunkeccAuth', data).then((res) => res.data);

/**
 * planCustomerId
 * @param data
 * @returns {*}
 */
export const getPhoneFromPlan = (data) => request.formData('/pc/newPersonalMission/getPhoneFromPlan', data);

export const querySeaAuth = (data) => request.get('/pc/customer/querySeaAuth', data).then((res) => tools.checkSelectList(res.data, 'id', 'seaName'));

export const toSeaList = (data) => request.postJson('/pc/newcustomer/seacustomers', data);

export const fromEnterpriseSeas = (data) => request.postJson('/pc/intentionPool/fromEnterpriseSeas', data);

export const getTimeLine = (data) => request.get('/pc/customer/getTimeLine', data).then((res) => res.data);

export const endCallAddWxFriend = (data) => request.postJson('/pc/customer/endCallAddWxFriend', data).then((res) => res.data);

export const recordInfo = (data) => request.formData('/pc/callLog/recordInfo', data).then((res) => res.data);

export const getRecordUrl = (data) => request.formData('/pc/callLog/getRecordUrl', data).then((res) => res.data);

export const callLogLastOrNext = (data) => request.postJson('/pc/callLog/callLogLastOrNext', data).then((res) => res.data);

export const setEnableSea = (data) => request.formData('/pc/seasetup/set_enable_sea', data);
