import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import {
  Drawer, Form, Tooltip, Upload, Input, Select, Button, message,
} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { changeCompanyinfo } from '@/service/user-service';

const { Option } = Select;

const industry = [
  'IT/通讯/电子/互联网',
  '金融业',
  '房地产/建筑业',
  '商业服务',
  '贸易/批发/零售',
  '文教传媒/体育娱乐',
  '生产/加工/制造',
  '仓储物流',
  '能源/矿产/环保',
  '政府/非盈利机构',
  '农林牧渔',
];

const propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  companyInfo: PropTypes.shape({
    imgUrl: PropTypes.string,
    name: PropTypes.string,
    companyAddress: PropTypes.string,
    descRemark: PropTypes.string,
    summary: PropTypes.string,
  }),
  getCompanyInfo: PropTypes.func,
};
const defaultProps = {
  visible: false,
  onClose: null,
  companyInfo: {},
  getCompanyInfo: null,
};

@inject(({ globalStore }) => ({
  companyInfo: globalStore.companyInfo,
  getCompanyInfo: globalStore.getCompanyInfo,
}))
@observer
class CompanyDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      comimgurl: '',
    };
  }

  /**
   * 图片转base64
   * @param {*} img
   * @param {*} callback
   */
  getBase64 = (img, callback) => {
    if (!img) return;
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  handleChange = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
    }
    this.getBase64(info.file.originFileObj, (imageUrl) => {
      this.setState({
        comimgurl: imageUrl,
        loading: false,
      });
    });
  }

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/bmp';
    if (!isJpgOrPng) {
      message.error('不支持此类文件');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('不能超过2MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  /**
   * 修改企业信息
   * @param {*} values
   */
  onFinish = async (values) => {
    const { getCompanyInfo, onClose } = this.props;
    const data = {
      comimgurl: (values?.imgUrl?.file?.originFileObj) || new File([], 'file', {}),
      ...values,
    };
    delete data.imgUrl;
    const response = await changeCompanyinfo(data);
    if (response.success) {
      onClose();
      getCompanyInfo();
    } else {
      message.error(response.message);
    }
  }

  onClose = () => {
    const { onClose } = this.props;
    this.setState({
      comimgurl: '',
    }, () => onClose());
  }

  render() {
    const { visible, companyInfo } = this.props;
    const { loading, comimgurl } = this.state;
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8, fontSize: 12 }}>
          点击可更换头像
        </div>
      </div>
    );
    return (
      <Drawer
        title="企业信息"
        placement="right"
        visible={visible}
        width={440}
        onClose={this.onClose}
        className="yunke-drawer"
        zIndex={1033}
      >
        <Form
          onFinish={this.onFinish}
          initialValues={{
            comname: companyInfo?.name || '',
            comimgurl: companyInfo?.imgUrl || '',
            comaddress: companyInfo?.companyAddress || '',
            comsummary: companyInfo?.summary || '',
            comdescremark: companyInfo?.descRemark || '',
          }}
        >
          <Tooltip title="点击可更换头像,支持JEPG、PNG、BMP格式大小不超过2M">
            <Form.Item label="企业头像" name="imgUrl">
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={this.beforeUpload}
                onChange={this.handleChange}
                customRequest={() => {}}
                fileList={[]}
              >
                {((comimgurl || companyInfo?.imgUrl) && !loading) ? (
                  <img
                    src={comimgurl || companyInfo?.imgUrl}
                    alt="avatar"
                    style={{ width: '100%' }}
                  />
                ) : uploadButton}
              </Upload>
            </Form.Item>
          </Tooltip>
          <Form.Item label="企业名称" name="comname" rules={[{ required: true, message: '该项为必填' }]}>
            <Input placeholder="请输入企业名称" maxLength={20} />
          </Form.Item>
          <Form.Item label="企业地址" name="comaddress">
            <Input placeholder="请输入企业地址" maxLength={50} />
          </Form.Item>
          <Form.Item label="企业简介" name="comsummary">
            <Input placeholder="请输入企业简介" />
          </Form.Item>
          <Form.Item label="所属行业" name="comdescremark">
            <Select placeholder="请选择所属行业">
              {industry.map((item) => (
                <Option
                  key={item}
                  value={item}
                >
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
            <Button type="primary" htmlType="submit">
              保存
            </Button>
            <Button
              style={{ marginLeft: 10 }}
              onClick={this.onClose}
            >
              取消
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    );
  }
}

CompanyDrawer.propTypes = propTypes;
CompanyDrawer.defaultProps = defaultProps;

export default CompanyDrawer;
