import { request } from 'yunkecom/dist/utils';

/**
 * 手机设置-获取当前配置
 */
export const getPhoneConfigs = (data) => request.postJson('/pc/config/configs', data).then((res) => res.data);

/**
 * 手机设置-全公司统一设置
 */
export const setCompanySetup = (data) => request.postJson('/pc/config/setCompanySetup', data).then((res) => res);

/**
 * /config/editUserOrdeptConfigs 手机风控设置保存
 */
export const editUserOrdeptConfigs = (data) => request.postJson('/pc/config/editUserOrDeptConfigs', data).then((res) => res);

/**
 * 获取公海领取客户规则
 */
export const getCollectCustomersConfigs = (data) => request.postJson('/pc/crm_t_crmtrule/receive/rule', data).then((res) => res.data);

/**
 * 保存公海领取客户规则
 */
export const AddCollectCustomersConfigs = (data) => request.postJson('/pc/crm_t_crmtrule/save/receiveRule', data).then((res) => res.data);

/**
 * 通过id查询分配的围栏配置
 */
export const getIdsByDepartmentId = (data) => request.postJson('/pc/circleFence/getIdsByDepartmentId', data).then((res) => res);

/**
 * 新增围栏设置
 */
export const saveGeomapConfig = (data) => request.postJson('/pc/circleFence/addOrUpdateCircleFence', data);

/**
 * 围栏列表
 */
export const getGeomapConfig = (data) => request.postJson('/pc/circleFence/circleFenceList', data);

/**
 * 围栏列表
 */
export const getGeomapConfigList = (data) => request.postJson('/pc/circleFence/getByIds', data);

/**
 * 删除
 */
export const delGeomapConfigById = (data) => request.postJson('/pc/circleFence/circleFenceDel', data);

/**
 * 更新状态
 */
export const changeStatusById = (data) => request.postJson('/pc/circleFence/updateStatus', data);
