import { observable, action } from 'mobx';
import Cookies from 'js-cookie';
import { userService } from '@/service';

class MessageStore {
  @observable ws = null // 与消息中心的websocket连接

  @observable messageList = [] // 消息列表

  @observable unreads = 0 // 未读消息数

  @observable lockReconnect= false // 是否真正建立连接

  @observable timeout= 10 * 1000 // 30秒一次心跳

  @observable timeoutnum= null // 断开重连倒计时

  @observable number = 0 // 积累链接次数

  @observable timeoutObj = null; // 心跳心跳倒计时

  @observable orderStatus = 0 // 订单支付状态

  @observable orderId = '1' // 订单 id

  @action init = async () => {
    const res = await userService.getMsgList({
      pageSize: 10,
      pageNum: 1,
      appId: 'yunkepc',
      msgType: null,
    });
    this.messageList = res?.data?.result ? res.data.result : [];
    this.unreads = res.data?.isNoReadCount;
  }

    @action reconnect =() => { // 重新连接
      if (this.lockReconnect) {
        return;
      }
      this.lockReconnect = true;
      // 设置延迟请求
      if (this.timeoutnum) {
        clearTimeout(this.timeout);
      }
      this.timeoutnum = setTimeout(() => {
        this.connectWS(); // 重连
        this.lockReconnect = false;
      }, 3000);
    }

   @action reset =() => { // 重置心跳
     clearInterval(this.timeoutObj); // 重置时间
     this.start(); // 重启心跳
   }

 @action start=() => { // 开启心跳
   if (this.timeoutObj) {
     clearInterval(this.timeout);
   }
   this.timeoutObj = setInterval(() => {
     // 发送一个心跳
     if (this.ws.readyState === 1) { // 连接正常
       this.ws.send('ok');
     } else { // 重连
       this.reconnect();
     }
   }, this.timeout);
 }

  /**
   * 读某条消息
   * @param {*} id 消息id
   * @param {*} isRead 该条消息是否已读
   */
  @action readMsg = (id, isRead) => {
    const msg = this.messageList.find((item) => item.id === id);
    if (msg) {
      // 将该条消息标为已读
      msg.isRead = 1;
    }
    if (isRead === 0) {
      // 如果该条消息未读，则将未读总数减一
      this.unreads -= 1;
    }
  }

  @action wscolse = (type) => {
    this.ws.close();
    if (type === 2) {
      this.orderStatus = null;
    }
  }

  @action connectWS = () => {
    // this.init(); // 消息中心
    if (process.env.REACT_APP_API_ENV === 'dev') {
      this.ws = new WebSocket('wss://netty.yunkecn.com:443/netty/ws');
    } else if (window.location.host === 'test.yunkecn.com') {
      this.ws = new WebSocket('wss://test.yunkecn.com:443/netty/ws');
    } else {
      this.ws = new WebSocket('wss://netty.yunkecn.com:443/netty/ws');
    }

    this.ws.onopen = () => {
      const obj = {
        WXZ_NETTY: 'USER_ONLINE_STATUS',
        appId: 'yunkepc',
        companyCode: Cookies.get('company'),
        userId: Cookies.get('userid'),
      };
      // 1表示连接已建立，可以进行通信
      if (this.ws.readyState === 1) {
        this.ws.send(JSON.stringify(obj));
        this.start();
        this.number += 1;
      }
    };
    this.ws.onmessage = (evt) => {
      this.reset();
      const { data } = evt;
      console.log(data);
      if (data.startsWith('{')) {
        const obj = JSON.parse(data);
        const { payload } = obj;
        this.orderStatus = payload?.extra?.orderStatus || 0;
        this.otherOrderId = payload?.extra?.otherOrderId || '';
      //   const msgContent = JSON.parse(obj.msgContent);
      //   // this.init();
      //   const notification = new Notification('消息中心', {
      //     body: msgContent.summary,
      //     // sound: 'audio/alert.mp3',
      //   });
      //   notification.onclick = () => {
      //     window.location.href = '/cms/home/messageCenter';
      //     // window.location.href = `/cms/home/messageCenter?id=${obj.id}`;
      //   };
      }
    };
    this.ws.onclose = () => {
      console.log('断开链接');
      if (this.number < 10) {
        this.reconnect();
      } // 重连
    };
    this.ws.onerror = (e) => {
      console.log(e);
      if (this.number < 10) {
        this.reconnect();
      } // 重连
    };
  }
}

export default new MessageStore();
