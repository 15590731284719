import { lazy } from 'react';

const Login = lazy(() => import('@/pages/auth/login'));
const Reg = lazy(() => import('@/pages/auth/reg'));
const ForgotPass = lazy(() => import('@/pages/auth/forgotpass'));
const Announce = lazy(() => import('@/pages/auth/announce'));
const AccountCheck = lazy(() => import('@/pages/auth/accountcheck'));
const ChangePhone = lazy(() => import('@/pages/auth/changephone'));
const Phonescreenshot = lazy(() => import('@/pages/home/phoneClient'));
const MobileCard = lazy(() => import('@/pages/home/mobilecard'));
const Geofencing = lazy(() => import('@/pages/geofencing'));

export default [
  {
    name: '登录',
    path: '/auth/login',
    key: 'login',
    component: Login,
  },
  {
    name: '注册',
    path: '/auth/reg',
    key: '/auth/reg',
    component: Reg,
  },
  {
    name: '忘记密码',
    path: '/auth/forgotpass',
    key: 'forgotpass',
    component: ForgotPass,
  },
  {
    name: '法律条款',
    path: '/auth/announce',
    key: 'announce',
    component: Announce,
  },
  {
    name: '安全验证',
    path: '/auth/accountcheck',
    key: 'accountcheck',
    component: AccountCheck,
  },
  {
    name: '更换手机号',
    path: '/auth/changephone',
    key: 'changephone',
    component: ChangePhone,
  },
  {
    name: '手机截屏',
    path: '/phonescreenshot',
    key: 'phonescreenshot',
    component: Phonescreenshot,
  },
  {
    name: '插拔卡统计',
    path: '/mobileCard',
    key: 'mobileCard',
    component: MobileCard,
  },
  {
    name: '围栏设置',
    path: '/geofencing',
    key: 'geofencing',
    component: Geofencing,
  },
];
