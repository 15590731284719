import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import {
  Drawer, Form, Tooltip, Upload, Button, Input, Radio, Row, Col, message,
} from 'antd';
import { LoadingOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import { CommonModal } from 'yunkecom';
import {
  findByDepartment, EditStaffList, uploadHeadImage, getYzm, changePhone, changePwd,
} from '@/service/user-service';

const transUserType = [
  '普通员工',
  '超级管理员',
  '普通管理员',
];

const propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.shape()),
  aloneUserInfo: PropTypes.func,
  showUserDrawer: PropTypes.bool,
  setShowUserDrawer: PropTypes.func,
  userDrawerPhone: PropTypes.string,
  userInfo: PropTypes.shape(),
};
const defaultProps = {
  permissions: [],
  aloneUserInfo: null,
  showUserDrawer: false,
  setShowUserDrawer: null,
  userDrawerPhone: null,
  userInfo: {},
};

@inject(({ globalStore }) => ({
  aloneUserInfo: globalStore.aloneUserInfo,
  showUserDrawer: globalStore.showUserDrawer,
  setShowUserDrawer: globalStore.setShowUserDrawer,
  userDrawerPhone: globalStore.userDrawerPhone,
  userInfo: globalStore.userInfo,
}))
@observer
class UserDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: {},
      comimgurl: '',
      loading: false,
      codeTime: 0, // 倒计时
      passwordVisible: false, // 修改密码弹窗
      phoneVisible: false, // 修改手机号弹窗
    };
    this.formRefUser = React.createRef();
    this.formRef = React.createRef();
    this.form2Ref = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { userDrawerPhone, userInfo } = this.props;
    if (userDrawerPhone !== prevProps.userDrawerPhone) {
      if (userDrawerPhone) {
        this.getUserInfo();
      } else {
        // eslint-disable-next-line
        this.setState({
          userInfo,
        }, () => {
          const { userInfo: userInfo1 } = this.state;
          if (this.formRefUser.current) {
            this.formRefUser.current.setFieldsValue(userInfo1);
          }
        });
      }
    } else if (prevProps.userInfo.id !== userInfo.id && !userDrawerPhone) {
      // eslint-disable-next-line
      this.setState({
        userInfo,
      }, () => {
        const { userInfo: userInfo1 } = this.state;
        if (this.formRefUser.current) {
          this.formRefUser.current.setFieldsValue(userInfo1);
        }
      });
    }
  }

  /**
   * 根据手机号获取用户信息
   */
  getUserInfo = async () => {
    const { userDrawerPhone } = this.props;
    const res = await findByDepartment({ realName: userDrawerPhone });
    const newUserInfo = res.users[0];
    this.setState({
      userInfo: {
        realName: newUserInfo.name,
        userType: newUserInfo.userType,
        userSex: newUserInfo.userSex,
        cellphone: newUserInfo.phone,
      },
    }, () => {
      const { userInfo } = this.state;
      this.formRefUser.current.setFieldsValue(userInfo);
    });
  }

  /**
   * 图片转base64
   * @param {*} img
   * @param {*} callback
   */
   getBase64 = (img, callback) => {
     if (!img) return;
     const reader = new FileReader();
     reader.addEventListener('load', () => callback(reader.result));
     reader.readAsDataURL(img);
   }

   /**
   * 上传头像之前的检查
   * @param {*} file
   * @returns
   */
   beforeUpload = (file) => {
     const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/bmp';
     if (!isJpgOrPng) {
       message.error('不支持此类文件');
     }
     const isLt2M = file.size / 1024 / 1024 < 2;
     if (!isLt2M) {
       message.error('不能超过2MB!');
     }
     return isJpgOrPng && isLt2M;
   }

  /**
   * 上传头像
   * @param {*} info
   */
  handleChange = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
    }
    this.getBase64(info.file.originFileObj, (imageUrl) => {
      this.setState({
        comimgurl: imageUrl,
        loading: false,
      });
    });
  }

  /**
   * 修改用户信息
   * @param {*} values
   */
  onUserFinish = async (values) => {
    const { aloneUserInfo, setShowUserDrawer } = this.props;
    const { comimgurl, userInfo } = this.state;
    if (comimgurl) {
      // 如果修改了头像提交之前先修改头像
      uploadHeadImage({ file: values?.headImg?.file?.originFileObj });
    }
    const data = {
      userRealname: values.realName,
      userSex: values.userSex,
      id: userInfo.id,
    };
    try {
      const response = await EditStaffList(data);
      if (response.success) {
        aloneUserInfo();
        setShowUserDrawer(false);
      } else {
        message.warning(response.message);
      }
    } catch (error) {
      message.warning(error.message);
    }
  }

  /**
   * 获取右侧按钮
   */
  getPermissions = () => {
    const { permissions } = this.props;
    if (permissions && Array.isArray(permissions)) {
      return permissions?.find(
        (item) => item.id === '1007',
      )?.children?.find(
        (item) => item.url === '/cms/settings/department',
      )?.buttons || [];
    }
    return [];
  }

  /**
   * 修改手机号
   * @param {*} values
   */
   onPhoneFinish = async (values) => {
     const { aloneUserInfo } = this.props;
     const { userInfo } = this.state;
     try {
       const response = await changePhone({
         id: userInfo.id,
         cellphone: values.cellphone,
         yzm: values.phoneCode,
       });
       if (response.success) {
         aloneUserInfo();
         this.setState({ phoneVisible: false });
         message.success(response.message);
       } else {
         message.error(response.message);
       }
     } catch (error) {
       message.error(error.message);
     }
   }

  /**
   * 获取验证码
   * @param {String} type changePwd 修改密码 changeCellphone修改手机号
   */
  getPhoneCode = async (type, cellphone) => {
    const { userInfo } = this.state;
    const response = await getYzm({
      cellphone: cellphone || userInfo.cellphone,
      type,
    });
    if (response.success) {
      // 倒计时
      this.setState({
        codeTime: response.data,
      }, () => {
        this.countDown();
      });
      message.success('验证码已发送，请注意接收');
    } else {
      message.warning(response.message);
    }
  }

  /**
   * 倒计时
   */
  countDown = () => {
    let { codeTime } = this.state;
    if (codeTime > 0) {
      codeTime -= 1;
      setTimeout(() => {
        this.countDown();
      }, 1000);
    } else {
      codeTime = 0;
    }
    this.setState({ codeTime });
  }

  /**
   * 修改密码
   * @param {*} values
   */
   onPasswordFinish = async (values) => {
     const { userInfo } = this.state;
     try {
       const response = await changePwd({
         id: userInfo.id,
         cellphone: userInfo.cellphone,
         oldPwd: values.transOldCode,
         newPwd: values.transNewCode,
         yzm: values.phoneCode,
       });
       if (response.success) {
         this.setState({ passwordVisible: false });
         message.success('修改成功');
       } else {
         message.error(response.message);
       }
     } catch (error) {
       message.warning(error.message);
     }
   }

   render() {
     const { showUserDrawer, setShowUserDrawer, userDrawerPhone } = this.props;
     const {
       comimgurl, userInfo, loading, codeTime, passwordVisible, phoneVisible,
     } = this.state;

     const paramsList = this.getPermissions();
     const name = !paramsList?.find((item) => item.url === '/pc/crm_t_portaluser/edit/nameInfo') || userDrawerPhone;
     const userSex = !paramsList?.find((item) => item.url === '/pc/crm_t_portaluser/edit/sexInfo') || userDrawerPhone;
     const phone1 = !paramsList?.find((item) => item.url === '/pc/crm_t_portaluser/edit/phoneInfo') || userDrawerPhone;

     const uploadButton = (
       <div>
         {loading ? <LoadingOutlined /> : <PlusOutlined />}
         <div style={{ marginTop: 8, fontSize: 12 }}>
           点击可更换头像
         </div>
       </div>
     );
     let avatar = <UserOutlined style={{ fontSize: 100 }} />;
     if (userDrawerPhone) {
       if (userInfo.headImg) {
         avatar = (
           <img
             src={userInfo.headImg}
             style={{ width: 100, height: 100 }}
             alt="头像"
           />
         );
       }
     } else {
       avatar = (
         <Upload
           name="avatar"
           listType="picture-card"
           className="avatar-uploader"
           showUploadList={false}
           beforeUpload={this.beforeUpload}
           onChange={this.handleChange}
           customRequest={() => {}}
           fileList={[]}
         >
           {(comimgurl || userInfo.headImg) ? (
             <img
               src={comimgurl || userInfo.headImg}
               alt="avatar"
               style={{ width: '100%' }}
             />
           ) : uploadButton}
         </Upload>
       );
     }

     return (
       <>
         <Drawer
           title="个人信息"
           placement="right"
           visible={showUserDrawer}
           width={440}
           className="yunke-drawer"
           zIndex={1111}
           onClose={() => {
             setShowUserDrawer(false);
           }}
         >
           <Form
             onFinish={this.onUserFinish}
             initialValues={{
               realName: userInfo.realName,
               userSex: userInfo.userSex,
             }}
             ref={this.formRefUser}
           >
             <Tooltip title="点击可更换头像,支持JEPG、PNG、BMP格式大小不超过2M">
               <Form.Item label="个人头像" name="headImg">
                 {avatar}
               </Form.Item>
             </Tooltip>
             <Form.Item
               label="姓名"
               name="realName"
               rules={[{ required: true, message: '该项为必填' }]}
             >
               <Input
                 disabled={name}
                 placeholder="请输入姓名"
                 maxLength={20}
               />
             </Form.Item>
             <Form.Item
               label="性别"
               name="userSex"
               rules={[{ required: true, message: '该项为必选' }]}
             >
               <Radio.Group
                 disabled={userSex}
                 buttonStyle="solid"
                 size="small"
               >
                 <Radio.Button value={1}>男</Radio.Button>
                 <Radio.Button value={0}>女</Radio.Button>
               </Radio.Group>
             </Form.Item>
             {!userDrawerPhone && (
             <Form.Item label="密码">
               <Button
                 type="primary"
                 size="small"
                 onClick={() => {
                   this.setState({
                     passwordVisible: true,
                   });
                   setShowUserDrawer(false);
                 }}
               >
                 修改密码
               </Button>
             </Form.Item>
             )}
             <Form.Item label="手机号">
               <a href={`tel:${userInfo.cellphone}`}>{userInfo.cellphone}</a>
               {!userDrawerPhone && (
               <Button
                 style={{ marginLeft: 10 }}
                 type="primary"
                 size="small"
                 disabled={phone1}
                 onClick={() => {
                   this.setState({
                     phoneVisible: true,
                   });
                   setShowUserDrawer(false);
                 }}
               >
                 修改手机号
               </Button>
               )}
             </Form.Item>
             <Form.Item label="职位">
               {userInfo.major}
             </Form.Item>
             <Form.Item label="角色">
               {transUserType[userInfo.userType]}
             </Form.Item>
             <Form.Item label="管理部门">
               {userInfo?.manageDepartments?.map((item) => item)}
             </Form.Item>
             {!userDrawerPhone && (
             <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
               <Button type="primary" htmlType="submit">
                 保存
               </Button>
               <Button
                 style={{ marginLeft: 10 }}
                 onClick={() => {
                   setShowUserDrawer(false);
                 }}
               >
                 取消
               </Button>
             </Form.Item>
             )}
           </Form>
         </Drawer>
         {passwordVisible && (
         <CommonModal
           title="修改密码"
           visible={passwordVisible}
           onClick={() => this.setState({
             passwordVisible: false,
             codeTime: 0,
           })}
           footer={(
             <>
               <Button
                 onClick={() => this.setState({
                   passwordVisible: false,
                   codeTime: 0,
                 })}
               >
                 取消
               </Button>
               <Button
                 type="primary"
                 onClick={() => this.formRef.current.submit()}
               >
                 保存
               </Button>
             </>
            )}
         >
           <Form
             onFinish={this.onPasswordFinish}
             initialValues={{
               cellphone: userInfo.cellphone,
               phoneCode: '',
               transOldCode: '',
               transNewCode: '',
               transSecondNewCode: '',
             }}
             ref={this.formRef}
           >
             <Form.Item
               label="手机号"
               name="cellphone"
               rules={[{ required: true, message: '该项为必填' }]}
             >
               <Input disabled />
             </Form.Item>
             <Form.Item
               label="验证码"
               name="phoneCode"
               rules={[{ required: true, message: '该项为必填' }]}
             >
               <Row gutter={8}>
                 <Col span={12}>
                   <Form.Item
                     noStyle
                     name="phoneCode"
                   >
                     <Input maxLength={20} autoComplete="off" />
                   </Form.Item>
                 </Col>
                 <Col span={12}>
                   <Button
                     disabled={!!codeTime}
                     type="primary"
                     onClick={() => this.getPhoneCode('changePwd')}
                   >
                     {codeTime ? `${codeTime}s` : '获取验证码'}
                   </Button>
                 </Col>
               </Row>
             </Form.Item>
             <Form.Item
               label="旧密码"
               name="transOldCode"
               rules={[{ required: true, message: '该项为必填' }]}
             >
               <Input
                 type="password"
                 placeholder="旧密码"
                 maxLength={20}
                 autoComplete="new-password"
               />
             </Form.Item>
             <Form.Item
               label="新密码"
               name="transNewCode"
               rules={[{ required: true, message: '该项为必填' }, {
                 validator: (rule, value, callback) => {
                   if (value.length < 6) {
                     callback('密码不能少于6位');
                   } else {
                     callback();
                   }
                 },
               }]}
             >
               <Input type="password" placeholder="新密码" maxLength={20} />
             </Form.Item>
             <Form.Item
               label="再次输入"
               name="transSecondNewCode"
               rules={[{ required: true, message: '该项为必填' }, {
                 validator: (rule, value, callback) => {
                   if (value !== this.formRef.current.getFieldValue('transNewCode')) {
                     callback('两次输入的不一致');
                   } else {
                     callback();
                   }
                 },
               }]}
             >
               <Input type="password" placeholder="请再次输入新密码" maxLength={20} />
             </Form.Item>
           </Form>
         </CommonModal>
         )}
         {phoneVisible && (
         <CommonModal
           title="修改手机号"
           visible={phoneVisible}
           onClick={() => this.setState({ phoneVisible: false, codeTime: 0 })}
           footer={(
             <>
               <Button onClick={() => this.setState({ phoneVisible: false, codeTime: 0 })}>
                 取消
               </Button>
               <Button type="primary" onClick={() => this.form2Ref.current.submit()}>
                 保存
               </Button>
             </>
            )}
         >
           <Form
             onFinish={this.onPhoneFinish}
             initialValues={{
               cellphone: userInfo.cellphone,
               phoneCode: '',
             }}
             ref={this.form2Ref}
           >
             <Form.Item label="手机号" name="cellphone" rules={[{ required: true, message: '该项为必填' }]}>
               <Input maxLength={11} />
             </Form.Item>
             <Form.Item label="验证码" name="phoneCode" rules={[{ required: true, message: '该项为必填' }]}>
               <Row gutter={8}>
                 <Col span={12}>
                   <Form.Item
                     noStyle
                     name="phoneCode"
                   >
                     <Input maxLength={20} />
                   </Form.Item>
                 </Col>
                 <Col span={12}>
                   <Button
                     disabled={!!codeTime}
                     type="primary"
                     onClick={() => this.getPhoneCode('changeCellphone', this.form2Ref.current.getFieldValue('cellphone'))}
                   >
                     {codeTime ? `${codeTime}s` : '获取验证码'}
                   </Button>
                 </Col>
               </Row>
             </Form.Item>
           </Form>
         </CommonModal>
         )}
       </>
     );
   }
}

UserDrawer.propTypes = propTypes;
UserDrawer.defaultProps = defaultProps;

export default UserDrawer;
