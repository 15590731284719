import { observable, action } from 'mobx';
import {
  initGlobalState,
} from 'qiankun';
import { userService } from '@/service';
import { logout } from '@/global';

class GlobalStore {
  actions = initGlobalState({
    userInfo: {},
    menus: [],
    showUserDrawer: false,
    userDrawerId: '',
    userDrawerPhone: '',
  });

  constructor() {
    this.actions.onGlobalStateChange((state) => {
      this.showUserDrawer = state.showUserDrawer;
      this.userDrawerId = state.userDrawerId;
      this.userDrawerPhone = state.userDrawerPhone;
    });
  }

  @observable userInfo = {}

  @observable companyInfo = {}

  @observable showMenu = true

  @observable showCompanyDrawer = false

  @observable showUserDrawer = false

  @observable userDrawerPhone = ''

  @observable userDrawerId = ''

  /**
   * 菜单信息
   */
  @observable menuInfo = []

  @observable currentMenu = [] // 当前的一级导航

  @action setShowUserDrawer = (showUserDrawer) => {
    this.actions.setGlobalState({
      showUserDrawer,
    });
  }

  @action setUserDrawerPhone = (userDrawerPhone) => {
    this.actions.setGlobalState({
      userDrawerPhone,
    });
  }

  @action setShowMenu = (showMenu) => { this.showMenu = showMenu; }

  @action getUserInfo = async () => {
    let menus = localStorage.getItem('permissions');
    let userInfo = localStorage.getItem('userData');
    let companyInfo = localStorage.getItem('companyInfo');
    if (!userInfo) {
      userInfo = await this.aloneUserInfo();
    }
    if (!menus) {
      // 如果localstorage里没有这些数据，需要从服务器获取
      // userInfo = await this.aloneUserInfo();
      companyInfo = await this.getCompanyInfo();
      menus = await userService.getMenu();
      if (!Array.isArray(menus)) {
        menus = [];
      }
      localStorage.setItem('permissions', JSON.stringify(menus));
    } else {
      // 如果localstrage里已经有了，则直接取
      menus = JSON.parse(menus);
      userInfo = JSON.parse(userInfo);
      companyInfo = JSON.parse(companyInfo);
    }
    this.menuInfo = menus;
    this.currentMenu = menus;
    this.companyInfo = companyInfo;
    this.userInfo = userInfo;
    // 这里把用户信息保存在qiankun状态中，子应用直接存在mox中
    this.actions.setGlobalState({
      userInfo,
      menus,
      showUserDrawer: false,
    });
  }

  @action setUserInfo = (userInfo, companyInfo, menus) => {
    this.menuInfo = menus;
    this.currentMenu = menus;
    this.companyInfo = companyInfo;
    this.userInfo = userInfo;
    this.actions.setGlobalState({
      userInfo,
      menus,
    });
  }

  @action aloneUserInfo = async () => {
    const userInfo = await userService.getUserInfo().catch((e) => {
      if (e.code === 302) {
        logout(e.data);
      }
    });
    if (userInfo) {
      this.userInfo = userInfo;
      localStorage.setItem('userData', JSON.stringify(userInfo));
    }

    return userInfo;
  }

  @action getCompanyInfo = async () => {
    const companyInfo = await userService.getCompanyInfo();
    this.companyInfo = companyInfo;
    localStorage.setItem('companyInfo', JSON.stringify(companyInfo));
    return companyInfo;
  }

  @action changeCompanyDrawer = (status) => {
    this.showCompanyDrawer = status;
  }

  @action clearMenuInfo = () => {
    this.menuInfo = [];
  }
}

export default new GlobalStore();
