import Cookies from 'js-cookie';

export const logout = async (url) => {
  Cookies.remove('userid');
  Cookies.remove('company');
  Cookies.remove('user', { path: '/', domain: 'yunkecn.com' });
  Cookies.remove('userToken', { path: '/', domain: 'yunkecn.com' });
  localStorage.clear();

  if (url) {
    window.location.href = url;
  }
};

/**
 * 将百度座标转换为高德座标
 * @param {*} item
 * @return 高德坐标
 */
export const bd09ToGcj02 = (item) => {
  const xPi = (3.14159265358979324 * 3000.0) / 180.0;
  const x = item.longitude - 0.0065;
  const y = item.latitude - 0.006;
  const z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * xPi);
  const theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * xPi);
  return {
    longitude: z * Math.cos(theta),
    latitude: z * Math.sin(theta),
  };
};
