import qs from 'qs';
import { request } from 'yunkecom/dist/utils';

/**
 * 双向外呼接口
 */

/**
 * 获取双向外呼个人设置
 * @returns {*}
 */
export const getBind = () => request.get('/pc/callproxy/getBind').then((res) => res.data);

/**
 * 绑定手机号
 * @param data
 * @returns {Promise<*>}
 */
export const bindPhone = (data) => request.postJson('/pc/callproxy/bindPhone', data).then((res) => res);

/**
 * 拨打双向外呼电话
 * @param data
 * @returns {Promise<*>}
 */
export const callout = (data) => request.postJson('/pc/callproxy/callout', data).then((res) => res.data);

/**
 * 获取外呼通道
 * @returns {Promise<*>}
 */
export const queryChannel = () => request.get('/pc/callproxy/queryChannel');

/**
 * 获取坐席信息
 * @param departmentName
 * @param userName
 * @param data
 * @returns {Promise<*>}
 */
export const getSeatList = ({ departmentName, userName, ...data }) => {
  const params = {
    departmentName: departmentName?.length > 0 ? departmentName[0].name : '',
    userName: Array.isArray(userName) ? userName[0]?.name : userName || '',
    ...data,
  };
  return request.postJson('/pc/callproxy/getSeatList', params).then((res) => res);
};

/**
 * 获取话单信息
 * @param departmentName
 * @param userName
 * @param data
 * @returns {Promise<*>}
 */
export const getBillList = ({ departmentName, userName, ...data }) => {
  const params = {
    departmentId: departmentName?.length > 0 ? departmentName[0].id : '',
    userId: userName?.length > 0 ? userName[0].id : '',
    ...data,
  };
  return request.postJson('/pc/callproxy/CallOutOrder', params).then((res) => res);
};
/**
 * 外呼统计
 * @returns {*}
 */
export const getQueryCallOutStatistics = () => request.get('/pc/callproxy/statistics').then((res) => res.data);

/**
 * 获取外呼配置
 * @param data
 * @returns {Promise<*>}
 */
export const getConfig = (data) => request.postJson('/pc/config/configs', data).then((res) => res.data);

/**
 * 获取公司可配置的套餐
 * @returns {*}
 */
export const getChannelType = () => request.get('/pc/callproxy/channelType').then((res) => res);

/**
 * 保存设置外呼
 * @param data
 * @returns {Promise<*>}
 */
export const editUserOutboundConfigs = (data) => request.postJson('/pc/config/editUserOrDeptConfigs', data).then((res) => res);

/**
 * 外呼设置全公司统一执行
 * @param data
 * @returns {Promise<*>}
 */
export const setCompanySetup = (data) => request.postJson('/pc/config/setCompanySetup', data).then((res) => res);

/**
 * 我的账户
 * @returns {*}
 */
export const getAmountInfo = () => request.get('/pc/callproxy/amountInfo').then((res) => res);

/**
 * 坐席列表导出
 */
export const exportCallOrder = ({ departmentName, userName, ...data }) => {
  const dataTwo = {
    departmentName: departmentName?.length > 0 ? departmentName[0].name : '',
    userName: Array.isArray(userName) ? userName[0]?.name : userName || '',
    ...data,
  };
  const params = qs.stringify(dataTwo);
  return request.postForm('/pc/callproxy/SeatList/export', params);
};

/**
 * 话单列表导出
 */
export const exportSeatList = ({ departmentName, userName, ...data }) => {
  const dataTwo = {
    departmentId: departmentName?.length > 0 ? departmentName[0].id : '',
    userId: userName?.length > 0 ? userName[0].id : '',
    ...data,
  };
  const params = qs.stringify(dataTwo);
  return request.postForm('/pc/callproxy/callOrder/export', params);
};

/**
 * 语音转文字
 * @param callActionId
 * @returns {1}
 */
export const recordInfo = (callActionId) => request.postForm(`/pc/callLog/recordInfo?callActionId=${callActionId}`);

/**
 * 获取真实的url地址
 * @param data
 * @returns {*}
 */
export const getRecordUrl = (data) => request.formData('/pc/callLog/getRecordUrl', data).then((res) => res.data);

/**
 * 中转外呼删除坐席
 */

export const deleteSeats = (data) => request.postForm('/pc/callproxy/deleteBindPhone', qs.stringify(data));

/**
 * 中转外呼解绑 坐席
 */
export const UnboundSeats = (data) => request.postForm('/pc/callproxy/unBindPhone', qs.stringify(data));
