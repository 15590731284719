import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import {
  Dropdown, Menu, Tabs, List, Badge, Avatar, Modal,
} from 'antd';
import {
  BellOutlined, UserOutlined,
  PhoneOutlined, LogoutOutlined, ExclamationCircleOutlined, DownloadOutlined, ImportOutlined,
} from '@ant-design/icons';
import { setAllMsgRead, loginOut } from '@/service/user-service';
import certified from '@/public/images/certified.png';
import IconEdit from '@/public/images/edit.png';
import MessageDetail from '@/pages/home/messageCenter/detail';
import { logout } from '@/global';
import CompanyDrawer from './companyDrawer';
import UserDrawer from './userDrawer';

const deploy = Object.freeze(process.env.REACT_APP_DEPLOY
  ? (JSON.parse(process.env.REACT_APP_DEPLOY)?.global || {}) : {});

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  companyInfo: PropTypes.shape({
    imgUrl: PropTypes.string,
    name: PropTypes.string,
    companyAddress: PropTypes.string,
    descRemark: PropTypes.string,
    summary: PropTypes.string,
  }),
  userInfo: PropTypes.shape({
    id: PropTypes.string,
    headImg: PropTypes.string,
    realName: PropTypes.string,
    userType: PropTypes.string,
    userSex: PropTypes.string,
    cellphone: PropTypes.string,
    major: PropTypes.string,
    manageDepartments: PropTypes.arrayOf(PropTypes.string),
  }),
  permissions: PropTypes.arrayOf(PropTypes.shape()),
  messageList: PropTypes.arrayOf(PropTypes.shape()),
  unreads: PropTypes.number,
  init: PropTypes.func,
  // setShowUserDrawer: PropTypes.func,
  // setUserDrawerPhone: PropTypes.func,
};
const defaultProps = {
  companyInfo: {},
  userInfo: {},
  permissions: [],
  messageList: [],
  unreads: 0,
  init: null,
  // setShowUserDrawer: null,
  // setUserDrawerPhone: null,
};

@inject(({ globalStore, messageStore }) => ({
  companyInfo: globalStore.companyInfo,
  userInfo: globalStore.userInfo,
  getCompanyInfo: globalStore.getCompanyInfo,
  currentMenu: globalStore.currentMenu,
  showUserDrawer: globalStore.showUserDrawer,
  setShowUserDrawer: globalStore.setShowUserDrawer,
  setUserDrawerPhone: globalStore.setUserDrawerPhone,
  messageList: messageStore.messageList,
  unreads: messageStore.unreads,
  init: messageStore.init,
}))
@observer
class TopRight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCompanyDrawer: false,
      showMsgDrawer: false,
      msgId: '',
    };
  }

  renderAvatarList = () => {
    const {
      userInfo, companyInfo,
      // setShowUserDrawer, setUserDrawerPhone,
    } = this.props;
    return (
      <Menu>
        <Menu.Item
          key="user"
          onClick={() => {
            // setUserDrawerPhone('');
            // setShowUserDrawer(true);
            window.location.href = '/cms/home/userDetail';
          }}
        >
          <div className="flex">
            <span className="text-xl font-medium">{userInfo.realName}</span>
            <img className="w-3 h-3 ml-4 mt-3" src={IconEdit} alt="" />
          </div>
        </Menu.Item>
        <Menu.Item
          key="company"
          onClick={() => { this.setState({ showCompanyDrawer: true }); }}
        >
          <div className="flex">
            <img className="w-3 h-4 mr-3 mt-1" src={certified} alt="" />
            <span className="overflow-ellipsis">{companyInfo?.name}</span>
            <img className="w-3 h-3 ml-3 mt-1" src={IconEdit} alt="" />
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item icon={<UserOutlined />} key="userDetail">
          <a href="/cms/home/userDetail">
            个人中心
          </a>
        </Menu.Item>
        {deploy.my_account && (userInfo.userType === 1 || userInfo.userType === 2) && (
          <Menu.Item icon={<UserOutlined />} key="account">
            <a href="/cms/home/myaccount">
              我的账户
            </a>
          </Menu.Item>
        )}
        <Menu.Item icon={<DownloadOutlined />} key="dowload">
          <a href="/cms/dowload">
            下载中心
          </a>
        </Menu.Item>
        {
          deploy.import_list && (
            <Menu.Item icon={<ImportOutlined />} key="import">
              <a href="/cms/import">
                导入记录
              </a>
            </Menu.Item>
          )
        }

        {deploy.dowload_soft_phone && (
          <Menu.Item icon={<PhoneOutlined />} key="soft_phone">
            <a
              href="https://yunke-pcfile.oss-cn-beijing.aliyuncs.com/d/telsoft.rar"
              target="_blank"
              rel="noreferrer"
            >
              下载软电话
            </a>
          </Menu.Item>
        )}
        <Menu.Divider />
        <Menu.Item
          key="exit"
          icon={<LogoutOutlined />}
          onClick={() => {
            Modal.confirm({
              title: '您确定要退出登录吗？',
              icon: <ExclamationCircleOutlined />,
              content: '您确定要退出登录吗？',
              okText: '确定',
              cancelText: '取消',
              onOk: async () => {
                if (window.location.hostname.startsWith('test')) {
                  await loginOut();
                }
                localStorage.clear();
                logout('/cms/auth/login');
              },
            });
          }}
        >
          退出登录
        </Menu.Item>
      </Menu>
    );
  }

  render() {
    const {
      userInfo, messageList, init, unreads, permissions,
    } = this.props;
    const {
      showCompanyDrawer, showMsgDrawer, msgId,
    } = this.state;

    return (
      <>
        <div className="flex items-center">
          {deploy.message_center && (
            <div className="mr-4 cursor-pointer">
              <Dropdown
                overlay={(
                  <Menu>
                    <Menu.Item>
                      <Tabs>
                        <Tabs.TabPane tab="消息中心">
                          <List
                            style={{
                              width: 300, height: 200, overflow: 'scroll',
                            }}
                            size="small"
                            split={false}
                            dataSource={messageList}
                            renderItem={(item) => {
                              let msgContent = {};
                              try {
                                msgContent = JSON.parse(item.msgContent);
                              } catch (e) {
                                msgContent = {
                                  summary: item.msgContent,
                                };
                              }
                              return (
                                <List.Item
                                  style={{ flexDirection: 'column', alignItems: 'flex-start' }}
                                  onClick={() => {
                                    this.setState({
                                      showMsgDrawer: true,
                                      msgId: item.id,
                                    });
                                  }}
                                >
                                  <div style={{ fontSize: 13, whiteSpace: 'normal' }}>
                                    {item.isRead === 0 && <Badge style={{ marginLeft: -14 }} status="error" />}
                                    [风控通知]
                                    {msgContent.summary}
                                  </div>
                                  <div style={{ fontSize: 12, color: '#999' }}>
                                    {moment(item.createTime).format('YYYY-MM-DD HH:mm:ss')}
                                  </div>
                                </List.Item>
                              );
                            }}
                          />
                          <div className="flex justify-between px-8 pt-2 border-t">
                            <div
                              role="button"
                              tabIndex="0"
                              onKeyPress={() => { }}
                              onClick={async () => {
                                await setAllMsgRead({});
                                init();
                              }}
                            >
                              全部已读
                            </div>
                            <div
                              role="button"
                              tabIndex="0"
                              onKeyPress={() => { }}
                              onClick={() => { window.location.href = '/cms/home/messageCenter'; }}
                            >
                              查看更多
                            </div>
                          </div>
                        </Tabs.TabPane>
                      </Tabs>
                    </Menu.Item>
                  </Menu>
                )}
                trigger={['click']}
              >
                <Badge
                  count={unreads}
                  style={{
                    boxShadow: 'none',
                  }}
                >
                  <BellOutlined style={{ color: '#ccc', fontSize: 24 }} />
                </Badge>
              </Dropdown>
            </div>
          )}
          <div className="mt2 mr-4">
            <Dropdown className="mt-2" overlay={this.renderAvatarList()}>
              <Avatar
                icon={<UserOutlined />}
                src={userInfo.headImg}
                alt="用户头像"
              />
            </Dropdown>
          </div>
        </div>
        <CompanyDrawer
          visible={showCompanyDrawer}
          onClose={() => this.setState({ showCompanyDrawer: false })}
        />
        <UserDrawer
          permissions={permissions}
        />
        <MessageDetail
          showDrawer={showMsgDrawer}
          msgId={msgId}
          onClose={() => {
            this.setState({
              showMsgDrawer: false,
            });
          }}
        />
      </>
    );
  }
}

TopRight.propTypes = propTypes;
TopRight.defaultProps = defaultProps;

export default TopRight;
