import React, { Suspense } from 'react';
import {
  ConfigProvider, Result, Button,
} from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import {
  BrowserRouter, Switch, Route, Redirect,
} from 'react-router-dom';
import { Provider } from 'mobx-react';
import { request } from 'yunkecom/dist/utils';
import 'intro.js/introjs.css';
import LayoutComponent from './pages/layout';
import * as allStore from './store';
import commonRouterConfig from './routes/common-router';
import homeRouterConfig from './routes/home-router';
import cluesRouterConfig from './routes/clues-router';
import deviceRouterConfig from './routes/device-router';

const routerConfig = [
  ...commonRouterConfig,
  ...homeRouterConfig,
  ...cluesRouterConfig,
  ...deviceRouterConfig,
];

/**
 * 403错误
 * @returns
 */
const NotAccess = () => (
  <Result
    status="403"
    title="403"
    subTitle="对不起，您无权访问此页面"
    extra={(
      <Button
        type="primary"
        onClick={() => {
          const menus = localStorage.getItem('permissions');
          if (menus) {
            window.location.href = '/cms/';
          } else {
            window.location.href = '/cms/auth/login';
          }
        }}
      >
        回到首页
      </Button>
    )}
  />
);

/**
 * 404错误
 * @returns
 */
// const NotFound = () => (
//   <Result
//     status="404"
//     title="404"
//     subTitle="对不起，您访问的页面不存在"
//     extra={(
//       <Button
//         type="primary"
//         onClick={() => { window.location.href = '/cms/'; }}
//       >
//         回到首页
//       </Button>
//     )}
//   />
// );

// request.setBaseURL('https://test.yunkecn.com');
request.setBaseURL('/');

/**
 * 根据菜单配置转换成路由组件
 */
function getRouteByConfig() {
  // TODO 这里要考虑初次登录没有localStorage的情况
  const menus = localStorage.getItem('permissions');
  let indexUrl = '/home/statistics';
  // 把所有服务器端没有设置的路由写在这里
  let permissions = [
    '/cms/auth/reg',
    '/cms/auth/login',
    '/cms/auth/forgotpass',
    '/cms/auth/announce',
    '/cms/auth/accountcheck',
    '/cms/auth/changephone',
    '/cms/dowload',
    '/cms/home/myaccount',
    '/cms/mission/detail',
    '/cms/mission/plan',
    '/cms/home/reportform/home/download',
    '/cms/home/reportform/wechat/wechatlist',
    '/cms/home/reportform/:module/detail',
    '/cms/home/reportform/:module/list',
    '/cms/clues/:project/:url',
    '/cms/clues/:project',
    '/cms/home/outboundList/:type',
    '/cms/home/electronicSales',
    '/cms/geofencing',
    '/cms/home/transferOutbound/:type',
    '/cms/home/OpenTransferOutbound',
    '/cms/home/pc/:url',
    '/cms/qualityTesting',
    '/cms/home/applyOutbound',
    '/cms/home/electronList',
    '/cms/import',
    '/cms/home/userDetail',
    '/cms/expenditureDetails',
  ];
  if (menus) {
    const loginMenus = JSON.parse(menus);
    if (loginMenus[0] && loginMenus[0].url) {
      indexUrl = loginMenus[0].url.replace('/cms', '');
      permissions = permissions.concat(loginMenus.map((item) => {
        let c = [];
        if (item.children) {
          c = item.children.map((item1) => {
            let d = [];
            if (item1.children) {
              d = item1.children.map((item2) => item2.url);
            }
            return [item1.url, ...d];
          }).flat();
        }
        return [item.url, ...c];
      }).flat());
    }
  }

  const route = routerConfig.map(
    (config) => {
      if (permissions.indexOf(`/cms${config.path}`) >= 0) {
        return (
          <Route
            key={config.key}
            path={config.path}
            exact={config.exact || false}
            render={(props) => (
              <config.component
                history={props.history}
                location={props.location}
                match={props.match}
                staticContext={props.staticContext}
                routes={config.routes}
                deploy={process.env.REACT_APP_DEPLOY
                  ? (JSON.parse(process.env.REACT_APP_DEPLOY)?.[config.key] || {}) : {}}
              />
            )}
          />
        );
      }
      return (
        <Route
          key={config.key}
          path={config.path}
          exact={config.exact || false}
          component={NotAccess}
        />
      );
    },
  );
  // 把首页缺省重定向
  route.push(
    <Route
      exact
      key="home"
      path="/"
    >
      <Redirect to={indexUrl} />
    </Route>,
  );
  return route;
}

function App() {
  return (
    <ConfigProvider locale={zhCN}>
      <BrowserRouter basename={process.env.REACT_APP_ROOT_BASE}>
        {/* eslint-disable-next-line */}
        <Provider {...allStore}>
          <LayoutComponent>
            <Suspense fallback={<div>Loading...</div>}>
              <Switch>
                {/* 根据路由的配置信息 设置路由组件 */}
                {getRouteByConfig()}
                {/* <Route key="404" component={NotFound} /> */}
              </Switch>
            </Suspense>
          </LayoutComponent>
        </Provider>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
