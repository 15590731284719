import { observable, action } from 'mobx';
import { userService } from '@/service';

class SiteStore {
  @observable siteInfo = {}

  @action getSiteInfo = async () => {
    let channelInfo = localStorage.getItem('channelInfo');
    if (!channelInfo || JSON.parse(channelInfo)?.privateValue === undefined) {
      channelInfo = await userService.getChannelInfo();
      if (channelInfo) { // 接口异常处理
        localStorage.setItem('channelInfo', JSON.stringify(channelInfo));
      }
    } else {
      channelInfo = JSON.parse(channelInfo);
    }
    this.siteInfo = channelInfo;
    // 添加favicon
    if (this.siteInfo.urlLogo) {
      let link = document.querySelector('link[rel="shortcut icon"]') || document.querySelector('link[rel="icon"]');
      if (!link) {
        link = document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = this.siteInfo.urlLogo;
    }
  }
}

export default new SiteStore();
